import * as actionTypes from '../constants';
import { updateObject } from '../../utils';

const initialState = {
  user: null,
  email: '',
  error: false,
};

const setUserInfo = (state, action) => {
  return updateObject(state, {
    user: action.payload,
  });
};

const setEmailUser = (state, action) => {
  return updateObject(state, {
    email: action.email,
  });
};

const setErrorUser = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const renovations = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.USER_SET_INFO:
      return setUserInfo(state, action);
    case actionTypes.USER_SET_EMAIL:
      return setEmailUser(state, action);
    case actionTypes.USER_SET_ERROR:
      return setErrorUser(state, action);
    case actionTypes.RESET:
      return initialState;
    default:
      return state;
  }
};

export default renovations;
