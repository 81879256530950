/* eslint-disable */
import * as yup from 'yup';
const message = {
  emty: 'Este campo no puede quedar vacío',
  notValid: 'La información ingresada no corresponde al Tipo de Identificación',
  minOrMax: 'El número de identificación no es válido',
  notValidPlate: 'La información ingresada no corresponde al Tipo de placa',
};
export const lastCharactersRuc = ['009', '008', '007', '006', '005', '004', '003', '002', '001'];
export const validationsDocument = {
  1: {
    pattern: /^[0-9]+$/,
    id: 'Cédula',
    min: 10,
    max: 10,
    name: 'C',
  },
  2: {
    pattern: /^[0-9]+$/,
    id: 'RUC',
    min: 13,
    max: 13,
    name: 'R',
  },
  3: {
    pattern: /^[a-zA-Z0-9]+$/,
    id: 'Pasaporte',
    min: 3,
    max: 20,
    name: 'P',
  },
};
/**
 * @param {string} val
 * @param {string} type
 * @returns {boolean}
 */

const elseType = (val) => {
  let mod;
  let provinceCode = 0;
  let coefficients;
  let indexVerification;
  let isNatural = false;

  if (parseInt(val[2]) < 6) {
    provinceCode = val[0] + val[1];
    if (+provinceCode >= 25 || +provinceCode < 1) return [];
    mod = 10;
    coefficients = '212121212';
    isNatural = true;
  } else {
    mod = 11;
    coefficients = val[2] === '9' ? '432765432' : '32765432';
  }
  indexVerification = parseInt(val[coefficients.length]);

  return [mod, coefficients, indexVerification, isNatural];
};

const naturalCondition = (isNatural, val) => {
  return isNatural && !['001', '002', '003'].includes(val.slice(-3));
};
const isJuridicVal = (val) => {
  if (val[2] === '9') return true;
};
const juridCondition = (isJuridic, val) => {
  return isJuridic && val.slice(-3) === '000';
};
const emptyValue = (val) => {
  return !val || val === undefined;
};
const resultCondition = (result, type, val) => {
  if (result >= 10 && (type === '1' || (type === '2' && parseInt(val[2]) < 6))) result = result - 9;
  return result;
};

const modCondition = (total, mod, indexVerification) => {
  return total % mod === 0 && indexVerification === 0;
};
export const validDocument = (val, type) => {
  if (emptyValue(val)) return;
  let total = 0;
  let mod;
  let coefficients;
  let indexVerification;
  let isJuridic = false;
  let isNatural = false;
  let provinceCode = 0;
  if (type === '1') {
    if (val[2] < 7) {
      provinceCode = val[0] + val[1];
      if (+provinceCode >= 25) return false;
      mod = 10;
      coefficients = '212121212';
      indexVerification = parseInt(val[val.length - 1]);
    }
  } else {
    const [modType, coefficientsType, indexVerificationType, isNaturalType] = elseType(val);
    mod = modType;
    coefficients = coefficientsType;
    indexVerification = indexVerificationType;
    isNatural = isNaturalType;
    isJuridic = isJuridicVal(val);
    if (naturalCondition(isNatural, val)) return false;
    if (juridCondition(isJuridic, val)) return false;
  }

  for (const index in coefficients) {
    let result = parseInt(coefficients[index]) * parseInt(val[index]);
    result = resultCondition(result, type, val);
    total += result;
  }
  if (modCondition(total, mod, indexVerification)) {
    return true;
  } else return mod - (total % mod) === indexVerification;
};

export const validPrivateRUC = (val, type) => {
  if (emptyValue(val)) return;
  let total = 0;
  const mod = 11;
  const coefficients = '432765432';
  const indexVerification = parseInt(val[coefficients.length]);
  let isValidDocument = false;

  for (const index in coefficients) {
    let result = parseInt(coefficients[index]) * parseInt(val[index]);
    result = resultCondition(result, type, val);
    total += result;
  }
  if (modCondition(total, mod, indexVerification)) {
    isValidDocument = true;
  } else isValidDocument = mod - (total % mod) === indexVerification;
  if (!isValidDocument && val?.length === 13) {
    isValidDocument = lastCharactersRuc.includes(val.slice(-3));
  }

  return isValidDocument;
};

export const validationPlates = {
  4: {
    pattern: /[a-zA-Z]{3}[-]\d{4}$/,
    id: 'PARTICULAR',
    max: 8,
  },
  6: {
    pattern: /[a-zA-Z0-9]{4,17}$/,
    id: 'RAMV',
    max: 17,
  },
};

export const shemaFormInit = yup.object().shape({
  branch: yup.object().required(message.emty),
  idType: yup.string().required(message.emty),
  rucType: yup.string().when('idType', {
    is: (val) => val === '2',
    then: yup.string().required(message.emty),
    otherwise: yup.string(),
  }),
  idNumber: yup
    .string()
    .required(message.emty)
    .when('idType', {
      is: (val) => val === '3',
      then: yup
        .string()
        .matches(validationsDocument[3].pattern, message.notValid)
        .min(validationsDocument[3].min, message.minOrMax)
        .max(validationsDocument[3].max, message.minOrMax),
      otherwise: yup.string().when('idType', {
        is: (val) => val === '1',
        then: yup
          .string()
          .matches(validationsDocument[1].pattern, message.notValid)
          .min(validationsDocument[1].min, message.minOrMax)
          .max(validationsDocument[1].max, message.minOrMax)
          .test('idNumber', message.notValid, (val) => {
            return validDocument(val, '1');
          }),
        otherwise: yup
          .string()
          .matches(validationsDocument[2].pattern, message.notValid)
          .min(validationsDocument[2].min, message.minOrMax)
          .max(validationsDocument[2].max, message.minOrMax)
          .test('idNumber', message.notValid, (val) => {
            return val[2] === '9' ? validPrivateRUC(val, '2') : validDocument(val, '2');
          }),
      }),
    }),
  plateType: yup.string().required(message.emty),
  plate: yup
    .string()
    .required(message.emty)
    .when('plateType', {
      is: (val) => val === '4',
      then: yup.string().matches(validationPlates[4].pattern, message.notValidPlate),
      otherwise: yup.string().matches(validationPlates[6].pattern, message.notValidPlate),
    }),
});

export const feedBackMsg = {
  maxLength: 'El número de identificación debe tener al menos 6 a 15',
  required: 'Este campo no puede quedar vacío',
  pattern: 'La información ingresada no corresponde al Tipo de placa',
  minLength: 'El número de identificación debe tener al menos 6 a 15',
};

export const usosVehiculo = {
  3: {
    id: 3,
    name: 'Particular',
    des: 'Vehículo de servicio particular, para transporte de personas sin fines lucrativos',
    classes: ['01', '02', '06', '08', '20', '21'],
    regex: /^[a-zA-Z]{3}[0-9]{3}$/,
    years: 30,
    max: 100000,
    min: 3000,
    maxValueAccesories: 0.2,
  },
  5: {
    id: 5,
    name: 'Comercial',
    des: 'Vehículo de servicio comercial o particular, utilizado para el transporte de materiales, mercancías o personas para cumplir con su actividad económica. Excluye vehículos de uso público, taxi, taxi amigo, taxi ejecutivo y vehículos para renta.',
    classes: ['01', '02', '06', '07', '08', '20', '21'],
    regex: /^[a-zA-Z]{3}[0-9]{3}$/,
    years: 12,
    maxHeavy: 70000,
    max: 100000,
    min: 3000,
    maxValueAccesoriesHeavy: 1,
    maxValueAccesories: 0.2,
  },
};

export const validationsMaritalStatus = {
  1: {
    id: 'SOLTERO',
  },
  2: {
    id: 'CASADO',
  },
  3: {
    id: 'ÚNION LIBRE',
  },
  4: {
    id: 'DIVORCIADO',
  },
  5: {
    id: 'VIUDO',
  },
};

export const validationActivityTypes = {
  523: { name: 'A - AGRICULTURA, GANADERÍA, SILVICULTURA Y PESCA.' },
  524: { name: 'B - EXPLOTACIÓN DE MINAS Y CANTERAS.' },
  525: { name: 'C - INDUSTRIAS MANUFACTURERAS.' },
  526: { name: 'D - SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO.' },
  527: {
    name: 'E - DISTRIBUCIÓN DE AGUA; ALCANTARILLADO, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO.',
  },
  528: { name: 'F - CONSTRUCCIÓN.' },
  529: { name: 'G - COMERCIO AL POR MAYOR Y AL POR MENOR REPARACIÓN DE VEHÍCULOS' },
  530: { name: 'H - TRANSPORTE Y ALMACENAMIENTO.' },
  531: { name: 'I - ACTIVIDADES DE ALOJAMIENTO Y DE SERVICIO DE COMIDAS.' },
  532: { name: 'J - INFORMACIÓN Y COMUNICACIÓN.' },
  533: { name: 'K - ACTIVIDADES FINANCIERAS Y DE SEGUROS.' },
  534: { name: 'L - ACTIVIDADES INMOBILIARIAS.' },
  535: { name: 'M - ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS.' },
  536: { name: 'N - ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO.' },
  537: { name: 'P - ENSEÑANZA.' },
  538: { name: 'Q - ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL.' },
  539: { name: 'R - ARTES, ENTRETENIMIENTO Y RECREACIÓN.' },
  540: { name: 'S - OTRAS ACTIVIDADES DE SERVICIOS.' },
  541: { name: 'T - ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES' },
};

export const vehicleGroup = {
  Liviano: { name: 'Liviano', years: 15 },
  Pesado: { name: 'Pesado', years: 9 },
  LIVIANO: { name: 'Liviano', years: 15 },
  PESADO: { name: 'Pesado', years: 9 },
};

export const addressesTypes = {
  13: {
    id: 'Correspondencia',
  },
};

export const vehicleColorTypes = {
  33: { id: 'PLATA' },
  37: { id: 'TURQUESA' },
  89: { id: 'VIOLETA' },
  426: { id: 'LILA' },
  48: { id: 'TOMATE' },
  234: { id: 'ROSA' },
  249: { id: 'COBRE' },
  9: { id: 'CELESTE' },
  171: { id: 'CREMA' },
  4: { id: 'ROJO' },
  5: { id: 'NARANJA' },
  7: { id: 'MORADO' },
  12: { id: 'CAFE' },
  14: { id: 'NEGRO' },
  3: { id: 'AMARILLO' },
  41: { id: 'PLOMO' },
  145: { id: 'BRONCE' },
  2: { id: 'AZUL' },
  13: { id: 'BLANCO' },
  17: { id: 'DORADO' },
  31: { id: 'GRIS' },
  16: { id: 'BEIGE' },
  32: { id: 'VINO' },
  40: { id: 'MARRON' },
  18: { id: 'PLATEADO' },
  192: { id: 'RUBY' },
  205: { id: 'MARFIL' },
  10: { id: 'ROSADO' },
  458: { id: 'VERDE' },
};
export const lastCompaniesTypes = {
  33: { id: 'PLATA' },
  16: { id: 'AIG METROPOLITANA' },
  55: { id: 'ASEGURADORA DEL SUR' },
  28: { id: 'CHUBB SEGUROS ECUADOR S.A.' },
  4: { id: 'SEGUROS CONDOR' },
  23: { id: 'ECUATORIANO SUIZA' },
  17: { id: 'SEGUROS EQUINOCCIAL' },
  52: { id: 'GENERALI' },
  48: { id: 'HISPANA' },
  40: { id: 'INTEROCEANICA C.A. DE SEGUROS.' },
  14: { id: 'LA UNION' },
  53: { id: 'LATINA SEGUROS C.A.' },
  3: { id: 'MAPFRE ATLAS' },
  5: { id: 'ORIENTE SEGUROS S.A.' },
  1: { id: 'SEGUROS ALIANZA S.A.' },
  24: { id: 'SEGUROS SUCRE S.A.' },
  29: { id: 'SEGUROS UNIDOS' },
  37: { id: 'ZURICH SEGUROS ECUADOR S.A.' },
  56: { id: 'SEGUROS COLON' },
  57: { id: 'SEGUROS CONSTITUCION' },
  58: { id: 'SWEADEN COMPAÑÍA DE SEGUROS S.A.' },
  59: { id: 'VAZSEGUROS S.A. COMPAÑÍA DE SEGUROS' },
  60: { id: 'AMASEGUROS' },
};
export const filterDashboardTypes = {
  1: {
    id: 'ID',
  },
  2: {
    id: 'Placa',
  },
  3: {
    id: 'Póliza',
  },
};

export const filterInputPlaceholders = {
  1: {
    id: 'Busca por N° de identificación',
    min: '3',
    max: '20',
  },
  2: {
    id: 'Busca por N° de placa',
    min: '4',
    max: '17',
  },
  3: {
    id: 'Busca por N° de póliza',
    min: '6',
    max: '6',
  },
};

export const filterSearchPortalTypes = {
  1: {
    id: 'Placa',
    field: 'plateNumber',
  },
  2: {
    id: 'Cotización',
    field: 'numberQuotation',
  },
  3: {
    id: 'Póliza',
    field: 'numberPolicy',
  },
  4: {
    id: 'Asegurado ID',
    field: 'idDriver',
  },
};

export const optionDebugTypes = {
  1: {
    id: 'Respuesta Modelo predictivo',
  },
  2: {
    id: 'Persona cotización',
  },
  3: {
    id: 'Comparar - Tarifa - Emisión Vs Cotización',
  },
  4: {
    id: 'Compara 2 - Tarifas',
  },
  5: {
    id: 'Consultar Cónyuge',
  },
};
