import * as actionTypes from '../constants';
import { updateObject } from '../../utils';

const initialState = {
  loading: false,
  error: undefined,
  searchVehicle: [],
  selectedVehicle: null,
  searchProvince: [],
};

const setError = (state, action) => {
  return updateObject(state, {
    error: action.payload,
  });
};

const setSearchVehicle = (state, action) => {
  return updateObject(state, {
    searchVehicle: action.payload,
  });
};

const setSelectedVehicle = (state, action) => {
  return updateObject(state, {
    selectedVehicle: action.payload,
  });
};

const setSearchProvince = (state, action) => {
  return updateObject(state, {
    searchProvince: action.payload,
  });
};

const setLoading = (state, action) => {
  return updateObject(state, {
    loading: action.payload,
  });
};

const search = (state = initialState, action = {}) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case actionTypes.SET_LOADING_SEARCH:
      return setLoading(state, action);
    case actionTypes.SET_ERROR_SEARCH:
      return setError(state, action);
    case actionTypes.SET_SEARCH_VEHICLE:
      return setSearchVehicle(state, action);
    case actionTypes.INFO_VEHICLE_SEARCHED:
      return setSelectedVehicle(state, action);
    case actionTypes.SET_SEARCH_PROVINCE:
      return setSearchProvince(state, action);
    default:
      return state;
  }
};

export default search;
