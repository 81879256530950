import * as actionTypes from '../constants';
import { updateObject } from '../../utils';

const initialState = {
  info: {},
  branches: {},
  agentRuc: null,
  loading: false,
  success: null,
  errors: null,
};

const setInfo = (state, action) => {
  return updateObject(state, {
    info: action.payload,
  });
};
const setRucBranch = (state, action) => {
  return updateObject(state, {
    branches: action.payload,
  });
};

const setAgentRuc = (state, action) => {
  return updateObject(state, {
    agentRuc: action.payload,
  });
};

const setIsLoading = (state, action) => {
  return updateObject(state, {
    loading: action.payload,
  });
};

const setErrorGlobal = (state, action) => {
  return updateObject(state, {
    errors: action.payload,
  });
};

const setSuccess = (state, action) => {
  return updateObject(state, {
    success: action.payload,
  });
};

const general = (state = initialState, action = {}) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case actionTypes.SET_INFO:
      return setInfo(state, action);
    case actionTypes.SET_RUC:
      return setRucBranch(state, action);
    case actionTypes.SET_AGENT_RUC:
      return setAgentRuc(state, action);
    case actionTypes.GENERAL_SET_IS_LOADING:
      return setIsLoading(state, action);
    case actionTypes.GENERAL_SET_ERROR:
      return setErrorGlobal(state, action);
    case actionTypes.GENERAL_SET_SUCCESS:
      return setSuccess(state, action);
    default:
      return state;
  }
};

export default general;
