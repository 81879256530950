import _ from 'lodash';

export const validateWhitespaces = (event, value) => {
  const whitespaces = (value.match(/\s/g) || []).length;
  if ((whitespaces > 0 || value === '') && event.keyCode === 32) return;

  return true;
};

export const truncate = (value, max) => {
  value = _.truncate(value, {
    length: max,
    omission: '',
  });

  return value;
};

export const removeSpecialCharacters = (value) => {
  value = value.replace(
    /[-(,´'@^~~ñÑ\\´¨¨!"#$%&/_()=?*[\]+{}`^,.¨¨;:|~<>¡¿¬""‘’ÜÀ°á-úá-úÁ-Ú\\]/g,
    '',
  );

  return value;
};

export const removeLetters = (value) => {
  value = value.replace(/[A-Za-z]/g, '');

  return value;
};

export const removeWhitespaces = (value) => {
  value = _.trim(value);
  value = _.replace(value, ' ', '');

  return value;
};

export const removeExtraSpaces = (stringValue) => {
  if (typeof stringValue !== 'string' || !stringValue) return '';
  stringValue = stringValue.split(' ').filter((piece) => piece.length > 0);
  stringValue = stringValue.join(' ');
  return stringValue;
};

export const onlyNumbers = (value) => {
  value = String(value).replace(/\D/g, '');

  return value;
};

export const addressMod = (value) => {
  value = value.replace(/[(@^~~\\´¨¨!"$%&/()=?*[\]+{}`^.¨¨;:|~<>¡¿¬""\\]/g, '');

  return value;
};

export const onlyLetters = (value) => {
  value = value.replace(/[(,@!´"#$%&/()=?*[\]+{}`^,.¨¨;:|~<>¡¿¬”"°\\0-9]/g, '');

  return value;
};

export const capitalize = (str = '') => {
  console.log(str);
  return _.startCase(_.toLower(str));
};

export const PadLeft = (value, length) => {
  return value.toString().length < length ? PadLeft('0' + value, length) : value;
};
