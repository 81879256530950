import axios from 'axios';

const axiosApiInstance = axios.create();
const redirectPortal = () => {
  sessionStorage.clear();
  window.location.replace(process.env.REACT_APP_INTERMEDIARIOS_URL);
};
axiosApiInstance.interceptors.request.use(async (config) => {
  const id_token = sessionStorage.getItem('id_token');
  const refresh_token = JSON.parse(sessionStorage.getItem('token_info') || '{}')?.refresh_token;
  config.headers['Authorization'] = `${id_token}`;
  config.headers['refreshtoken'] = `${refresh_token}`;
  config.headers['Content-Type'] = 'application/json';
  return config;
});

axiosApiInstance.interceptors.response.use(
  (response) => {
    const tokenInfo = response?.data?.tokenAuth;
    if (tokenInfo) {
      const parsedTokenInfo = JSON.parse(tokenInfo);
      sessionStorage.setItem('id_token', parsedTokenInfo.id_token);
      sessionStorage.setItem('token_info', tokenInfo);
      delete response.data.tokenAuth;
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 403) redirectPortal();
    return Promise.reject(error);
  },
);

const getAccessToken = (token, allTokenInfo) => {
  sessionStorage.setItem('id_token', token);
  allTokenInfo.user_profile = {
    nameUser: allTokenInfo?.user_profile?.nameUser,
    rucUser: allTokenInfo?.user_profile?.rucUser,
    mail: allTokenInfo?.user_profile?.mail,
    displayName: allTokenInfo?.user_profile?.displayName,
  };
  sessionStorage.setItem('token_info', JSON.stringify(allTokenInfo));
  return Promise.resolve(token);
};

export { axiosApiInstance, getAccessToken };
