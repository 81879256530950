import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import store from './store/store';
import * as serviceWorker from './serviceWorker';

import './assets/styles/index.scss';

if (process.env.NODE_ENV === 'production') {
  console.log = (e) => e;
  console.warn = (e) => e;
  console.error = (e) => e;
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// Si deseas que tu aplicación funcione sin conexión y cargue más rápido,
// puedes cambiar unregister() por register() a continuación.
// Aprende más sobre los service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
