/* eslint-disable */
import {
  getInfoDriver,
  getInfoPerson,
  getPageInfoPerson,
  saveDataPersonService,
} from '../../services/quotation';
import { PersonformBody } from '../../utils';
import * as actionTypes from '../constants';
import { setError, setLoading } from './quotation';

export const setInfoPerson = (data) => {
  return {
    type: actionTypes.SET_INFO_PERSON,
    payload: data,
  };
};

export const setInfoDriver = (data) => {
  return {
    type: actionTypes.SET_INFO_DRIVER,
    payload: data,
  };
};

export const setPersonPage = (data) => {
  return {
    type: actionTypes.SET_PERSON_PAGE,
    payload: data,
  };
};

export const infoDriverAction = (idType, idNumber) => (dispatch) => {
  dispatch(setLoading(true));
  getInfoDriver(idType, idNumber)
    .then((response) => {
      dispatch(setInfoDriver(response.data));
    })
    .catch((e) => {
      e.response.status !== 400 &&
        dispatch(setError({ noDriverData: true, status: e?.response?.status }));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const infoPersonAction = (id, callback) => (dispatch) => {
  dispatch(setLoading(true));
  getInfoPerson(id)
    .then(({ data }) => {
      dispatch(setInfoPerson(data.data));
      dispatch(setError(null));
    })
    .catch((e) => {
      dispatch(setError({ noPersonData: true, status: e?.response?.status }));
    })
    .finally(() => {
      callback();
      dispatch(setLoading(false));
    });
};

export const saveDataInfoPerson =
  (body, history, redirect, goBack, goNext) => (dispatch, getState) => {
    dispatch(setLoading(true));
    const idQuotation = getState().quotation.quotations.quotation.id;
    const idType = getState().quotation.quotationsRequest.idType;
    const rucType = getState().quotation.quotationsRequest.rucType || '';
    const data = PersonformBody(idType, rucType, body);
    saveDataPersonService({ data }, idQuotation)
      .then((r) => {
        redirect && history.push('/dashboard');
        goBack && goBack();
        goNext && goNext();
      })
      .catch((e) => {
        redirect && history.push('/dashboard');
        if (e.response.status === 400) {
          dispatch(setError({ noPersonSave: true, status: e?.response?.status }));
          dispatch(setLoading(false));
        }
      })
      .finally(() => {
        !goNext && dispatch(setLoading(false));
      });
  };

export const getPagePersonAction =
  (id, goBack, callbackRedirect = false) =>
  (dispatch) => {
    dispatch(setLoading(true));
    getPageInfoPerson(id)
      .then(({ data }) => {
        dispatch(setPersonPage(data.data));
      })
      .catch((e) => console.log(e))
      .finally(() => {
        !goBack && dispatch(setLoading(false));
        if (callbackRedirect) callbackRedirect();
      });
  };
