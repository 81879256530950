import Dropdown from 'components/basics/dropdown/Dropdown';
import React, { useState } from 'react';

const DropdownCoverage = ({ displayList, defaultValue, id, onClickDeducible }) => {
  const [deducibleValue, setDeducibleValue] = useState(defaultValue);

  return (
    <div>
      <Dropdown
        onClick={(value) => {
          setDeducibleValue(value);
          const selectedDeductible = displayList.find(
            (currentD) => currentD.name === value,
          ).completeDeductible;
          onClickDeducible(selectedDeductible);
        }}
        id={id}
        dropdownFilter
        showAsterisk={false}
        displayList={displayList}
        quote
        className="h-13 w-80 outline-none rounded border-graySecondary border px-3 text-grayText text-base font-thin font-roboto text-left"
        boxClassName="bg-white w-80 h-40 overflow-auto scrollbar right-0  z-10"
        itemsClassName="hover:bg-lightTeal h-12 cursor-pointer px-7 flex items-center btn-bold"
        boxContainer="absolute left-0 bg-white flex justify-center z-10 w-80 pr-1 h-40"
        iconClass="absolute -right-12 top-5 z-10"
        label={deducibleValue}
        gradientClassName="w-8 h-5 absolute -right-10 top-4"
        gradient
      />
    </div>
  );
};

export default DropdownCoverage;
