/*eslint-disable*/
import DropdownCoverage from 'components/collections/Quotation/DropdownCoverage';
import icon_done from '../../src/assets/images/icon_done.svg';
import Checkbox from '../components/basics/checkbox/checkbox';
import Dropdown from '../components/basics/dropdown/Dropdown';
import { formatNumberES, parseReRate, parseRequestEarnixDeductible } from './parseUtils';

export default function parseRowTAble(propsRowTable) {
  const {
    coverage,
    id,
    setCoverageSelected,
    colSelected,
    setValue,
    watch,
    control,
    Controller,
    saveCoverages,
    quotations,
    setParsedCoverageToSave,
  } = propsRowTable;
  let tableRow = { row: [] };
  let assitanceRow = { row: [] };
  let numAssistance;
  let counter = 0;

  const condition = (obj) => {
    return obj.obligatorio && obj.visibleFront && obj.tipoCobAsis != 2;
  };

  function rowValidate(obj, idx, e) {
    if (condition(obj)) {
      counter++;
    }
    const check = `check-${obj.name}-${obj.id}`;
    obj.checked && counter++;

    obj.visibleFront &&
      obj.tipoCobAsis != 2 &&
      tableRow.row.push({
        id: obj.obligatorio ? (
          <img src={icon_done} alt="icon_done" />
        ) : (
          <Controller
            name={check}
            control={control}
            render={({ field }) => (
              <Checkbox
                checkSelected={obj.checked}
                disabled={false}
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.checked);
                  setCoverageSelected((prevState) => {
                    if (prevState[idx]) {
                      delete prevState[idx];
                    } else {
                      prevState[idx] = obj;
                    }
                    return {
                      ...prevState,
                    };
                  });
                  !watch(check)
                    ? setValue(`${check}-value-${obj.id}`, undefined)
                    : setValue(`${check}-value-${obj.id}`, obj.id);
                  const toSaved = parseReRate(watch(`${check}-value-${obj.id}`), e.id, obj.id, obj);
                  setParsedCoverageToSave(toSaved);
                  saveCoverages({ body: toSaved, id: quotations?.quotation?.id });
                }}
                noWarning
              />
            )}
          />
        ),
        cobertura: `${obj.name}`,
        capital: capitalRender(obj),
        deducible: deducibleRender(obj, e.id),
      });
    obj.tipoCobAsis === 2 &&
      obj.visibleFront &&
      assitanceRow.row.push({
        id: <></>,
        cobertura: (
          <div className="flex flex-row gap-5 justify-center mr-10">
            <img
              src={icon_done}
              alt="icon_done"
              style={{ width: 16.56, height: 15.82, alignSelf: 'center' }}
            />{' '}
            {obj.name}
          </div>
        ),
      });
  }

  const capitalRender = (object) => {
    if (object?.capitals?.length > 1) {
      return (
        <Dropdown
          quote
          className="h-13 w-64 outline-none rounded border-graySecondary border px-3 text-grayText text-base font-thin font-roboto text-left"
          boxClassName="bg-white w-64 h-40 overflow-auto scrollbar right-0  z-10"
          itemsClassName="hover:bg-lightTeal h-12 cursor-pointer px-7 flex items-center btn-bold"
          boxContainer="absolute left-0 bg-white flex justify-center z-10 w-64 pr-1 h-40"
          label={`$ ${formatNumberES(object?.capitals[0].value)}`}
          displayList={object?.capitals
            ?.filter((e) => e?.value > 0)
            .map((e) => ({
              id: formatNumberES(e.value),
              name: `$ ${formatNumberES(e.value)}`,
            }))}
          showAsterisk={false}
        />
      );
    } else if (object?.capitals[0]?.value === 0 || object.capitals?.length === 0) return '';
    else return `USD $ ${formatNumberES(object?.capitals[0]?.value)}`;
  };

  const deducibleRender = (deduc, planSelectedId) => {
    const defaultDeducText = deduc.deductibles?.find(
      (currentDeductible) => currentDeductible.default === true,
    )?.deductible;

    if (deduc?.deducibleSeleccionable) {
      return (
        <DropdownCoverage
          displayList={deduc?.deductibles?.map((e) => ({
            id: e?.deductible,
            name: e?.deductible,
            completeDeductible: e,
          }))}
          defaultValue={defaultDeducText}
          id={deduc.id}
          onClickDeducible={(selectedDeducible) => {
            const requestEarnix = parseRequestEarnixDeductible(selectedDeducible, planSelectedId);
            console.log('enviando a earnix', requestEarnix);
            setParsedCoverageToSave(requestEarnix);
            saveCoverages({ body: requestEarnix, id: quotations?.quotation?.id });
          }}
        />
      );
    } else if (deduc.deductibles[0]?.deductible) return defaultDeducText;
    else return '';
  };
  coverage?.plans?.map((e) => {
    id == e.id && e.coverages.map((obj, idx) => rowValidate(obj, idx, e));
  });
  colSelected.current = counter;
  numAssistance = assitanceRow?.row?.length;
  return { tableRow, assitanceRow, numAssistance, colSelected };
}
