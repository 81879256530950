import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Loading from '../components/templates/misc/loading/Loading';
import { getClientInfo } from '../store/actions';

const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'));

const Navigator = () => {
  const loadingTemplate = () => <Loading />;

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClientInfo());
  }, [dispatch]);

  return (
    <React.Suspense fallback={loadingTemplate()}>
      <BrowserRouter>
        <DefaultLayout user={user} />
      </BrowserRouter>
    </React.Suspense>
  );
};

export default Navigator;
