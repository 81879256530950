// SEARCH

// POLICY

// USER
export const USER_SET_INFO = 'USER_SET_INFO';
export const USER_SET_EMAIL = 'USER_SET_EMAIL';
export const USER_SET_ERROR = 'USER_SET_ERROR';
export const SET_LOADING_USER = 'SET_LOADING_USER';

//GLOBAL
export const RESET = 'RESET';
export const UNSET_ERROR = 'UNSET_ERROR';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';

// QUOTATION
export const SET_QUOTATION = 'SET_QUOTATION';
export const SET_QUOTATION_REQUEST = 'SET_QUOTATION_REQUEST';
export const SET_LOADING_QUATATIONS = 'SET_LOADING_QUATATIONS';
export const SET_ERROR_QUATATION = 'SET_ERROR_QUATATION';
export const SET_INFO_VEHICULO = 'SET_INFO_VEHICULO';
export const SET_VEHICLE_PAGE = 'SET_VEHICLE_PAGE';
export const SET_RESET_STATE_QUOTE = 'SET_RESET_STATE_QUOTE';
export const SET_DASHBOARD_COUNT = 'SET_DASHBOARD_COUNT';

// EMISSION
export const SET_EMISSION = 'SET_EMISSION';
export const GET_EMISSION = 'GET_EMISSION';
export const SET_LOADING_EMISSION = 'SET_LOADING_EMISSION';
export const SET_ERROR_EMISSION = 'SET_ERROR_EMISSION';
export const SET_RESET_STATE_EMISSION = 'SET_RESET_STATE_EMISSION';
export const SET_VEHICLE_EMISSION = 'SET_VEHICLE_EMISSION';
export const SET_VEHICLE_INSPECTION = 'SET_VEHICLE_INSPECTION';
export const SET_EMISSION_FV = 'SET_EMISSION_FV';
export const SET_ERROR_EMISSION_FV = 'SET_ERROR_EMISSION_FV';
export const SET_ERROR_VEHICLE_INSPECTION = 'SET_ERROR_VEHICLE_INSPECTION';
export const SET_EMISSION_DASHBOARD = 'SET_EMISSION_DASHBOARD';
export const SET_ACCESORIES_LIST = 'SET_ACCESORIES_LIST';
export const SET_ROLE_RESUME = 'SET_ROLE_RESUME';
export const SET_ROLE_GOBACK = 'SET_ROLE_GOBACK';
export const SET_PURCHASE_SUMMARY = 'SET_PURCHASE_SUMMARY';
export const SET_QUOTE_EMITTED = 'SET_QUOTE_EMITTED';
export const SET_INSPECTION_STATUS = 'SET_INSPECTION_STATUS';

// predictive search
export const SET_SEARCH_VEHICLE = 'SET_SEARCH_VEHICLE';
export const SET_SEARCH_PROVINCE = 'SET_SEARCH_PROVINCE';
export const SET_LOADING_SEARCH = 'SET_LOADING_SEARCH';
export const SET_ERROR_SEARCH = 'SET_ERROR_SEARCH';
export const INFO_VEHICLE_SEARCHED = 'INFO_VEHICLE_SEARCHED';
export const INFO_PROVINCE_SEARCHED = 'INFO_PROVINCE_SEARCHED';
export const SET_RESTRICTED_VEHICLE = 'SET_RESTRICTED_VEHICLE';

//general
export const SET_INFO = 'SET_INFO';
export const SET_RUC = 'SET_RUC';
export const SET_AGENT_RUC = 'SET_AGENT_RUC';
export const GENERAL_SET_IS_LOADING = 'GENERAL_SET_IS_LOADING';
export const GENERAL_SET_ERROR = 'GENERAL_SET_ERROR';
export const GENERAL_SET_SUCCESS = 'GENERAL_SET_SUCCESS';

//person
export const SET_INFO_PERSON = 'SET_INFO_PERSON';
export const SET_INFO_DRIVER = 'SET_INFO_DRIVER';
export const SET_PERSON_PAGE = 'SET_PERSON_PAGE';
//QUOTATION
export const SET_COVERAGE = 'SET_COVERAGE';
export const SET_COVERAGE_RULEID = 'SET_COVERAGE_RULEID';
export const SET_DASHBOARD_QUOTE = 'SET_DASHBOARD_QUOTE';

// DEBUG
export const SET_DEBUG_QUOTATIONS = 'SET_DEBUG_QUOTATIONS';
export const SET_DEBUG_INEMISSION = 'SET_DEBUG_INEMISSION';
export const SET_DEBUG_EMITTED = 'SET_DEBUG_EMITTED';
export const SET_DEBUG_CANCEL_QUOTATION = 'SET_DEBUG_CANCEL_QUOTATION';
export const SET_DEBUG_CANCEL_INEMISSION = 'SET_DEBUG_CANCEL_INEMISSION';
export const SET_DEBUG_LOADING = 'SET_DEBUG_LOADING';
export const SET_DEBUG_LOADING_EMITTED = 'SET_DEBUG_LOADING_EMITTED';
export const SET_DEBUG_LOADING_INEMISSION = 'SET_DEBUG_LOADING_INEMISSION';
export const SET_DEBUG_LOADING_QUOTATIONS = 'SET_DEBUG_LOADING_QUOTATIONS';
export const SET_DEBUG_RESET = 'SET_DEBUG_RESET';
export const SET_DEBUG_LINK_FORM = 'SET_DEBUG_LINK_FORM';
export const SET_DEBUG_CANCELED = 'SET_DEBUG_CANCELED';
export const SET_DEBUG_DOCUMENTS = 'SET_DEBUG_DOCUMENTS';
export const SET_DEBUG_TARIFF = 'SET_DEBUG_TARIFF';

export const SET_FINANCIAL_ENTITIES = 'SET_FINANCIAL_ENTITIES';
export const SET_SELECTED_ENTITY = 'SET_SELECTED_ENTITY';
