import { searchProvinceService, searchVehicleService } from '../../services/quotation';
import { setLoading as loading } from './quotation';
import * as actionTypes from '../constants';

export const setSearchVehicle = (data) => {
  return {
    type: actionTypes.SET_SEARCH_VEHICLE,
    payload: data,
  };
};
export const setSearchProvince = (data) => {
  return {
    type: actionTypes.SET_SEARCH_PROVINCE,
    payload: data,
  };
};
const setLoading = (data) => {
  return {
    type: actionTypes.SET_LOADING_SEARCH,
    payload: data,
  };
};

export const setSelectedVehicle = (data) => {
  return {
    type: actionTypes.INFO_VEHICLE_SEARCHED,
    payload: data,
  };
};

export const resetSearchVehicle = () => (dispatch) => {
  dispatch(setSearchVehicle([]));
};

export const resetSearchProvince = () => (dispatch) => {
  dispatch(setSearchProvince([]));
};

export const getVehicleInfo = (search, setInfo) => (dispatch) => {
  setInfo && dispatch(loading(true));
  const dataSend = {
    search,
  };

  searchVehicleService({ data: dataSend })
    .then((dataResponse) => {
      const { vehicles } = dataResponse.data.data;
      !setInfo && dispatch(setSearchVehicle(vehicles));
      setInfo && dispatch(setSelectedVehicle(dataResponse.data.data[0]));
    })
    .catch(console.log)
    .finally(() => {
      dispatch(setLoading(false));
      setInfo && dispatch(loading(false));
    });
};

export const getProvinceInfo = (search, setInfo) => (dispatch) => {
  setInfo && dispatch(loading(true));

  const dataSend = {
    search,
  };

  searchProvinceService({ data: dataSend })
    .then((dataResponse) => {
      dispatch(setSearchProvince(dataResponse.data.data));
    })
    .catch(console.log)
    .finally(() => {
      dispatch(setLoading(false));
      setInfo && dispatch(loading(false));
    });
};
