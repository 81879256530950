import { parseEmissionDashboardItems, updateObject } from '../../utils';
import * as actionTypes from '../constants';

const initialState = {
  loading: false,
  error: false,
  emissionResponse: null,
  vehicleInformation: null,
  emissionTable: null,
  emissionTableItems: null,
  inspection: null,
  errorInspection: null,
  EmisionFV: null,
  errorEmisionFV: null,
  getEmissionData: null,
  accesoriesList: [],
  summaryValue: null,
  emitted: null,
  financialEntities: [],
  selectedEntity: null,
  statusInspection: null,
};

const setResetStateEmission = () => {
  return {
    ...initialState,
  };
};

const setEmission = (state, action) => {
  return updateObject(state, {
    emissionResponse: action.payload,
  });
};

const getEmission = (state, action) => {
  return updateObject(state, {
    getEmissionData: action.payload,
  });
};

const setLoadingEmission = (state, action) => {
  return updateObject(state, {
    loading: action.payload,
  });
};

const setErrorEmission = (state, action) => {
  return updateObject(state, {
    error: action.payload,
  });
};

const setErrorEmissionFv = (state, action) => {
  return updateObject(state, {
    errorEmisionFV: action.payload,
  });
};

const setVehicleEmission = (state, action) => {
  return updateObject(state, {
    vehicleInformation: { ...state.vehicleInformation, ...action.payload },
  });
};

const setVehicleEmissionFv = (state, action) => {
  return updateObject(state, {
    EmisionFV: action.payload,
  });
};

const setInspectioEmission = (state, action) => {
  return updateObject(state, {
    inspection: action.payload,
  });
};

const fetchInspectionSuccess = (state, action) => {
  return updateObject(state, {
    statusInspection: action.payload,
  });
};

const setErrorInspectioEmission = (state, action) => {
  return updateObject(state, {
    errorInspection: action.payload,
  });
};

const setInfoEmissionDashboard = (state, action) => {
  return updateObject(state, {
    emissionTableItems: parseEmissionDashboardItems(
      action.payload.data.Items,
      action.payload.userData,
    ),
    emissionTable: action.payload.data,
  });
};
const setAccesoriesList = (state, action) => {
  return updateObject(state, {
    accesoriesList: action.payload,
  });
};

const setEmissionRoleResume = (state) => {
  return updateObject(state, {
    emissionResponse: { ...state.emissionResponse, roleResume: false },
  });
};

const setPurchaseSummary = (state, action) => {
  return updateObject(state, {
    summaryValue: action.payload,
  });
};

const setQuoteEmitted = (state, action) => {
  return updateObject(state, {
    emitted: action.payload,
  });
};

const setEmissionRoleGoBack = (state) => {
  return updateObject(state, {
    emissionResponse: { ...state.emissionResponse, roleResume: true },
  });
};

const setFinancialEntities = (state, action) => {
  const entities = action.payload?.data || []; // Ajusta seg�n la estructura de tu JSON
  return updateObject(state, {
    financialEntities: entities,
  });
};

const setSelectedEntity = (state, action) => {
  return updateObject(state, {
    selectedEntity: action.payload,
  });
};

const emission = (state = initialState, action = {}) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case actionTypes.SET_EMISSION:
      return setEmission(state, action);
    case actionTypes.GET_EMISSION:
      return getEmission(state, action);
    case actionTypes.SET_LOADING_EMISSION:
      return setLoadingEmission(state, action);
    case actionTypes.SET_ERROR_EMISSION:
      return setErrorEmission(state, action);
    case actionTypes.SET_ERROR_VEHICLE_INSPECTION:
      return setErrorInspectioEmission(state, action);
    case actionTypes.SET_RESET_STATE_EMISSION:
      return setResetStateEmission();
    case actionTypes.SET_VEHICLE_INSPECTION:
      return setInspectioEmission(state, action);
    case actionTypes.SET_EMISSION_FV:
      return setVehicleEmissionFv(state, action);
    case actionTypes.SET_ERROR_EMISSION_FV:
      return setErrorEmissionFv(state, action);
    case actionTypes.SET_VEHICLE_EMISSION:
      return setVehicleEmission(state, action);
    case actionTypes.SET_EMISSION_DASHBOARD:
      return setInfoEmissionDashboard(state, action);
    case actionTypes.SET_ACCESORIES_LIST:
      return setAccesoriesList(state, action);
    case actionTypes.SET_ROLE_RESUME:
      return setEmissionRoleResume(state);
    case actionTypes.SET_ROLE_GOBACK:
      return setEmissionRoleGoBack(state);
    case actionTypes.SET_PURCHASE_SUMMARY:
      return setPurchaseSummary(state, action);
    case actionTypes.SET_QUOTE_EMITTED:
      return setQuoteEmitted(state, action);
    case actionTypes.SET_FINANCIAL_ENTITIES:
      return setFinancialEntities(state, action);
    case actionTypes.SET_SELECTED_ENTITY:
      return setSelectedEntity(state, action);
    case actionTypes.SET_INSPECTION_STATUS:
      return fetchInspectionSuccess(state, action);
    default:
      return state;
  }
};

export default emission;
