import fileDownload from 'js-file-download';
import { Promise } from 'q';
import { Buffer } from 'buffer';
import { axiosApiInstance } from 'utils/serviceUtils';
const URL = process.env.REACT_APP_BACKEND_API_URL;

export const uploadFile = (file, urlComplement, continuity) => {
  return Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let arrayAuxiliar = [];
      let base64 = reader.result;
      arrayAuxiliar = base64.split(',');
      let formdata = new FormData();
      formdata.append('request_file', arrayAuxiliar[1]);
      formdata.append(
        'data',
        JSON.stringify({ file_name: file.name, continuity, invoice: !continuity }),
      );
      axiosApiInstance
        .post(`${URL}${urlComplement}/upload-file`, formdata)
        .then(async (response) => {
          console.log(response);
          resolve({
            Nombre: response.data?.data?.file_name,
            Ruta: response.data?.data?.folder_name,
            Status: response.status,
          });
        })
        .catch((error) => reject(error));
    };
  });
};

export const downloadFile = (fileKey, folderName, urlComplement) => {
  return Promise((resolve, reject) => {
    axiosApiInstance
      .post(`${URL}${urlComplement}/get-file`, { data: { fileKey, folderName } })
      .then(async (response) => {
        console.log(response.data.data.data);
        try {
          const buffer = Buffer.from(response.data.data.data, 'base64');
          const fileName = fileKey;
          fileDownload(buffer, fileName);
          console.log(buffer);
        } catch (error) {
          console.log(error);
        }
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};
