import Axios from 'axios';
import { axiosApiInstance } from 'utils/serviceUtils';

const URL = process.env.REACT_APP_BACKEND_API_URL;

export const validationData = (body) => axiosApiInstance.post(`${URL}/quotation/new`, body); //validate data to continue with second step.

export const getInfoVehicle = (id) => axiosApiInstance.get(`${URL}/quotation/${id}/vehicle`);

export const searchVehicleService = (body) =>
  axiosApiInstance.post(`${URL}/quotation/vehicle-search`, body);

export const validateDataVehicleService = (body) =>
  axiosApiInstance.post(`${URL}/quotation/validateDataVehicle`, body);

export const saveDataVehicleService = (body, id) =>
  axiosApiInstance.put(`${URL}/quotation/${id}/vehicle`, body);
export const getBranch = (body) => axiosApiInstance.post(`${URL}/general/branch-offices`, body);

export const getInfoPerson = (id) => axiosApiInstance.get(`${URL}/quotation/${id}/person`);

export const getInfoDriver = (idType, idNumber) =>
  axiosApiInstance.get(`${URL}/quotation/driver/info?id_type=${idType}&id_number=${idNumber}`);

export const searchProvinceService = (body) =>
  axiosApiInstance.post(`${URL}/quotation/province-search`, body);

export const saveDataPersonService = (body, id) =>
  axiosApiInstance.put(`${URL}/quotation/${id}/person`, body);

export const getCoverageAndAssistance = (id) =>
  axiosApiInstance.get(`${URL}/quotation/${id}/coverage`);

export const getPageInfoPerson = (id) =>
  axiosApiInstance.get(`${URL}/quotation/${id}/getInfoScreenPerson`);

export const getDashboard = (body) =>
  axiosApiInstance.post(`${URL}/quotation/dashboard-quote`, body);

export const saveDataCoverages = (body, id) =>
  axiosApiInstance.put(`${URL}/quotation/${id}/coverage`, body);

export const getPDF = (id) => axiosApiInstance.get(`${URL}/quotation/${id}/get-pdf`);

export const resume = (id) => axiosApiInstance.get(`${URL}/quotation/${id}/resume`);

export const getDashboardCountService = (body) =>
  axiosApiInstance.post(`${URL}/quotation/dashboard/count`, body);

export const sessionSignIn = (code, refresToken) =>
  Axios.post(
    `${URL}/session/signin`,
    {},
    { headers: { Authorization: JSON.stringify({ code, refresToken }) } },
  );
