import { validationPlates, validationsDocument } from '../../models/schemas';
import {
  getInfoVehicle,
  saveDataVehicleService,
  validationData,
  getPDF,
  getDashboard,
  resume,
  getPageInfoPerson,
  getCoverageAndAssistance,
  saveDataCoverages,
  getDashboardCountService,
} from '../../services/quotation';
import * as actionTypes from '../constants';
import fileDownload from 'js-file-download';
import { Buffer } from 'buffer';
import _ from 'lodash';
import { setPersonPage } from './person';
import { setCoverage, setCoverageRuleId } from './quotationStep';

export const setQuotations = (data) => {
  return {
    type: actionTypes.SET_QUOTATION,
    payload: data,
  };
};

export const setDashboardCount = (data) => {
  return {
    type: actionTypes.SET_DASHBOARD_COUNT,
    payload: data,
  };
};

export const setError = (data) => {
  return {
    type: actionTypes.SET_ERROR_QUATATION,
    payload: data,
  };
};

export const setResetInitialState = () => {
  return {
    type: actionTypes.SET_RESET_STATE_QUOTE,
  };
};

export const setInfoVehicle = (data) => {
  return {
    type: actionTypes.SET_INFO_VEHICULO,
    payload: data,
  };
};

export const setInfoDashboard = (data) => {
  return {
    type: actionTypes.SET_DASHBOARD_QUOTE,
    payload: data,
  };
};

export const setLoading = (data) => {
  return {
    type: actionTypes.SET_LOADING_QUATATIONS,
    payload: data,
  };
};

export const setLoadingUser = (data) => {
  return {
    type: actionTypes.SET_LOADING_USER,
    payload: data,
  };
};

export const setQuotationsRequest = (data) => {
  return {
    type: actionTypes.SET_QUOTATION_REQUEST,
    payload: data,
  };
};

export const getDashboardCount = () => async (dispatch, getState) => {
  const agentRuc = getState().general.agentRuc;
  try {
    const data = {
      brokerKey: +agentRuc,
    };
    const res = await getDashboardCountService({ data });
    dispatch(setDashboardCount(res.data?.data));
  } catch (error) {
    console.log(error);
  }
};

export const infoVehicle = (id) => (dispatch) => {
  dispatch(setLoading(true));
  let search = false;
  getInfoVehicle(id)
    .then(({ data }) => {
      dispatch(setInfoVehicle(data.data));
    })
    .catch((e) => {
      dispatch(setError({ noVehicleData: true, status: e?.response?.status }));
    })
    .finally(() => {
      !search && dispatch(setLoading(false));
    });
};

export const getPDFQuotation = (coverage) => (dispatch, getState) => {
  dispatch(setLoading(true));
  const id = getState().quotation.quotations.quotation.id;
  saveDataCoverages({ data: { ...coverage, retarificar: false } }, id)
    .then(() => {
      getPDF(id)
        .then(({ data }) => {
          fileDownload(Buffer.from(data.data.file.body, 'base64'), data.data.file.name);
          dispatch(setError(null));
        })
        .catch((e) => {
          dispatch(setError({ failedPdf: true, status: e?.response?.status }));
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    })
    .catch(() => dispatch(setLoading(false)));
};

export const validateQuotation = (body, agentRuc, history) => (dispatch, getState) => {
  const userInfo = getState().user.user;
  const userMail = userInfo.mail;
  dispatch(setLoading(true));
  const request = {
    data: {
      quotation: {
        office_id: body.branch.id,
        agent_ruc: agentRuc,
        branch_office: body.branch.name,
        plate: body.plate,
        plate_type: validationPlates[body.plateType].id,
        id_number: body.idNumber,
        id_type: validationsDocument[body.idType].name,
        ruc_type: body.rucType,
        loggedMail: userMail,
      },
    },
  };

  dispatch(setQuotationsRequest(body));
  validationData(request)
    .then((r) => {
      dispatch(setError(null));
      dispatch(setQuotations(r.data.data)); //quotations response.
    })
    .catch((e) => {
      if (e?.response?.status === 400 && e?.response?.data?.data?.vehicle_found) {
        dispatch(setError({ vehicleActive: true }));
      } else if (e?.response?.status === 400 && e?.response?.data?.data?.restricted_brand) {
        dispatch(setError({ restricted_brand: true }));
      } else if (e?.response?.status === 400 && e?.response?.data?.data?.restrictedPlate) {
        dispatch(setError({ restrictedPlate: true }));
      } else {
        history?.push({
          pathname: '/noconnection',
          state: { status: e?.response?.status },
        });
      }
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const saveDataVehicle = (body, history, redirect, goNext) => (dispatch, getState) => {
  dispatch(setLoading(true));
  const regex = /[(,!\s"#$%&/()=?'*[\]+{}`^,.\-;:_|~<>¡¿¬”"°\\aA-zZ·ªºñ]/g;
  const plate = getState().quotation?.quotationsRequest.plate;
  const plateType = getState().quotation?.quotationsRequest.plateType;
  const idQuotation = getState().quotation.quotations.quotation.id;
  const vehicle = {
    use_code: body.uso_vehiculo === '' ? undefined : +body.uso_vehiculo,
    accepted: body.checkSelected,
    plate_type: validationPlates[plateType]?.id,
    plate: plate,
    brand: body.datosVehiculo.brand,
    model: body.datosVehiculo.model,
    vehicle_type: body.datosVehiculo.type,
    insured_value: body.datosVehiculo.insured_value
      ? +body.datosVehiculo.insured_value?.replace(regex, '')
      : undefined,
    year: body.datosVehiculo.year,
    group: body.datosVehiculo.group,
    is_new: body.zerokm,
    has_accessories: body.accesorios,
    accessories_value:
      body.accesorios_value === '' || body.accesorios === false
        ? undefined
        : +body.accesorios_value?.replace(regex, ''),
    has_satellite_device: body.has_satellite_device,
    vehicle_text: body.datosVehiculo.text === '' ? undefined : body.datosVehiculo.text,
  };
  const data = { vehicle };

  saveDataVehicleService({ data }, idQuotation)
    .then((r) => {
      console.log(r.data);
      redirect && history.push('/dashboard');
      goNext && goNext();
    })
    .catch(() => {
      redirect && history.push('/dashboard');
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const getDashboardQuote =
  ({ filter, all = true, perPage = 50, next, nextPage }) =>
  (dispatch, getState) => {
    dispatch(setLoading(true));
    const oldState = getState();
    const body = {
      data: {
        all: all,
        perPage: perPage,
        filter: filter ? filter : undefined,
        next: next ? next : undefined,
        brokerKey: +oldState?.general?.agentRuc,
      },
    };

    getDashboard(body)
      .then(({ data }) => {
        const newData = [
          ...(oldState?.quotation?.dashboardquote?.Items || []),
          ...(data?.data?.Items || []),
        ];
        next
          ? (() => {
              dispatch(setInfoDashboard({ ...data.data, Items: newData }));
              data.data && nextPage();
            })()
          : dispatch(setInfoDashboard({ Items: [], ...data.data }));
      })
      .catch((e) => {
        console.log('error', e);
        dispatch(setError({ status: e?.response?.status }));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

export const getQuotation =
  ({ id, goToQuotation }) =>
  (dispatch) => {
    dispatch(setLoading(true));
    resume(id)
      .then(({ data: { data } }) => {
        const quotation = data.quotation;
        const idType = _.find(_.toPairs(validationsDocument), (entries) => {
          return entries[1].name === quotation.id_type;
        });
        const plateType = _.find(_.toPairs(validationPlates), (entries) => {
          return entries[1].id === quotation.plate_type;
        });

        const newdata = {
          id,
          branch: {
            id: quotation.office_id,
            name: quotation.branch_office,
          },
          agentRuc: quotation.agent_ruc,
          plate: quotation.plate,
          plateType: plateType[0],
          idNumber: quotation.id_number,
          idType: idType[0],
          rucType: quotation.ruc_type,
          quotationStep: quotation.quotation_step,
        };

        if (quotation.quotation_step === 2) {
          dispatch(setError(null));
          goToQuotation();
          dispatch(setLoading(false));
        }
        if (quotation.quotation_step === 3) {
          getPageInfoPerson(id)
            .then((dataResponse) => {
              dispatch(setPersonPage(dataResponse.data.data));
              dispatch(setError(null));
              goToQuotation();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => dispatch(setLoading(false)));
        }
        if (quotation.quotation_step === 4) {
          getCoverageAndAssistance(id)
            .then((dataResponse) => {
              dispatch(setCoverage(dataResponse.data.data));
              console.log('----- CoberturaRuleId on GetQuotation------');
              console.log(dataResponse.data.data.CoberturaRuleId);
              const _ruleIdFactors = {
                CoberturaRuleId: dataResponse.data.data.CoberturaRuleId,
              };
              dispatch(setCoverageRuleId(_ruleIdFactors));
              dispatch(setError(null));
            })
            .catch((e) => {
              console.log(e, 'error');
              if (e?.response?.data?.data?.code === '2') {
                dispatch(
                  setError({
                    noCoverages: true,
                    status: e?.response?.status,
                    codError: 2,
                    isUpdating: false,
                  }),
                );

                return;
              }
              dispatch(
                setError({
                  noCoverages: true,
                  status: e?.response?.status || 500,
                  codError: 1,
                  isUpdating: false,
                }),
              );
            })
            .finally(() => {
              goToQuotation();
              dispatch(setLoading(false));
            });
        }

        dispatch(setQuotationsRequest(newdata));
        dispatch(setQuotations({ quotation: newdata }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setLoading(false));
      });
  };
