import publicIp from 'public-ip';
import { getBranch } from '../../services/quotation';
import * as actionTypes from '../constants';
import { setLoading } from './quotation';

const setInfo = (data) => {
  return {
    type: actionTypes.SET_INFO,
    payload: data,
  };
};

/**
 *
 * @param {boolean} isLoading
 */
export const setIsLoading = (loading) => {
  return {
    type: actionTypes.GENERAL_SET_IS_LOADING,
    payload: loading,
  };
};

/**
 * @typedef {Object} Success
 * @property {string} message  success description
 * @property {number} code code of success for example 2xx
 * @property {object} information more information of data
 * @param {Success | null} data  Description of success object
 */
export const setSuccess = (data) => {
  return {
    type: actionTypes.GENERAL_SET_SUCCESS,
    payload: data,
  };
};
/**
 * @typedef {Object} Error
 * @property {string} message  error description
 * @property {number} code code of error for example 4xx, 5xx
 * @property {object} information more information of data
 * @param {Error | null} error  Description of error object
 */
export const setErrorGlobal = (error) => {
  return {
    type: actionTypes.GENERAL_SET_ERROR,
    payload: error,
  };
};

export const getClientInfo = () => async (dispatch) => {
  try {
    const IPv4 = await publicIp.v4();
    dispatch(setInfo({ IPv4 }));
  } catch (error) {
    console.log(error);
  }
};
export const setRucBranch = (data) => {
  return {
    type: actionTypes.SET_RUC,
    payload: data,
  };
};
export const setAgentRucDashboard = (data) => {
  return {
    type: actionTypes.SET_AGENT_RUC,
    payload: data,
  };
};
export const getRucBranch = () => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const agentRuc = getState().general.agentRuc;
  try {
    const { data } = await getBranch({
      data: {
        ruc: `${agentRuc}`,
      },
    });
    dispatch(setRucBranch(data.data));
  } catch (error) {
    console.log(error);
  }
  dispatch(setLoading(false));
};
