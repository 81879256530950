import React, { useEffect, useState, useRef } from 'react';
import Loader from '../../../../assets/images/loader.svg';
import LibertyArmYellow from '../../../../assets/images/liberty-arm-yellow.svg';

const Loading = () => {
  const [show, setShoow] = useState(false);
  const buttonRef = useRef();
  useEffect(() => {
    const to = setTimeout(() => {
      setShoow(true);
    }, 15000);
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
    return () => {
      clearTimeout(to);
    };
  }, []);

  return (
    <button className="fixed inset-0" style={{ zIndex: 2000, cursor: 'default' }} ref={buttonRef}>
      <div className="fixed inset-0 bg-blackButtonSession opacity-95"></div>
      <div className="h-full flex justify-center items-center">
        <img alt="" src={Loader} className="absolute w-40" />
        <img alt="" src={LibertyArmYellow} className="absolute w-20" />
        {show && (
          <p
            className="z-50 text-white text-2xl font-robotoBold absolute text-center"
            style={{ width: '640px', bottom: '30%' }}
          >
            Este proceso puede tardar algunos segundos más,
            <br /> por favor espera mientras seguimos realizando la consulta.
          </p>
        )}
      </div>
    </button>
  );
};

export default Loading;
