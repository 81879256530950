/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import tapDown from '../../../assets/images/arrowDown.svg';
import './styles.scss';
import alertIcon from '../../../assets/images/error.svg';

const Dropdown = ({
  className,
  disabled = false,
  displayList = [],
  boxClassName,
  itemsClassName,
  onClick = (f) => f,
  register,
  label = '',
  defaultText,
  boxContainer = 'absolute right-0 bg-white flex justify-center z-20',
  id = '',
  trigger = (f) => f,
  floatLabelClass = 'text-xs font-robotoLight text-grayText',
  labelClass = 'text-ligthDark font-normal truncate',
  iconClass = 'absolute right-3 top-6 z-10',
  error = false,
  showIcon = true,
  gradient,
  errors,
  buttonClassname = 'w-full text-left text-sm font-roboto text-darkBlue font-medium',
  dateError,
  UlContainer = 'bg-white',
  imgStyle = { width: 18, height: 9 },
  required = false,
  resetDriver,
  quote,
  showAsterisk = true,
  eventListenerName = 'root',
  dropdownFilter = false,
  trigerData = true,
  newErrorMessage = false,
  gradientClassName = 'w-16 h-5 absolute right-0.5 top-7',
}) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState('');
  const [touched, setTouched] = useState(false);
  const touch = useRef(false);
  const handleClick = (element) => {
    onClick(register.name, element, { shouldValidate: true });
    setShow(false);
    setValue(element);
    resetDriver && resetDriver();
  };
  const handleFilter = (element) => {
    onClick(element);
    setShow(false);
  };
  useEffect(() => {
    value !== '' && (() => trigerData && trigger())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const eventListener = (e) => {
      if (!document.getElementById(id)?.contains(e.target)) {
        setShow(false);
      }
    };
    document.getElementById(eventListenerName).addEventListener('click', eventListener);
    return () =>
      document.getElementById(eventListenerName).removeEventListener('click', eventListener);
  }, [id]);

  const gradientClass = () => {
    if (disabled && !dateError) {
      return 'color-gradientDisabled';
    } else if (dateError) {
      return 'color-gradientError';
    }
    return 'color-gradient';
  };

  return (
    <div className="flex items-end">
      <div>
        <div className={!quote ? `relative` : `relative w-64`} id={id}>
          {errors && (
            <div className="absolute items-start text-left flex flex-row w-84 -top-6">
              <div>
                <img src={alertIcon} alt="error" />
              </div>
              <div className="font-robotoRegular text-xs text-lmRed items-start text-left ml-2">
                {newErrorMessage ? 'Es necesario realizar una selección' : errors?.message}
              </div>
            </div>
          )}
          <button
            className={`${className} btn-dropdown ${
              (error || errors || dateError || (required && touched && !value)) &&
              'btn-dropdown-error'
            } ${disabled && 'btn-dropdown-disabled'}`}
            onClick={() => {
              setTouched(true);
              setShow(!show);
            }}
            type="button"
            title={label}
            disabled={disabled}
            name={register?.name}
            onBlur={(e) => {
              !touch.current && register?.onBlur(e);
            }}
            ref={register?.ref}
          >
            {label.length === 0 ? (
              <>
                {defaultText?.replace('*', '')}
                {defaultText?.includes('*') && showAsterisk && (
                  <span className="text-mediumTeal text-base font-roboto">*</span>
                )}
              </>
            ) : (
              <span>
                <p className={floatLabelClass}>
                  {defaultText?.replace('*', '')}
                  {showAsterisk && (
                    <span className="text-mediumTeal text-base font-roboto"> *</span>
                  )}
                </p>
                <p className={labelClass}>{label}</p>
              </span>
            )}
            {showIcon && <img src={tapDown} alt="tapDown" className={iconClass} style={imgStyle} />}
            {gradient && <div className={`${gradientClassName} ${gradientClass()}`}></div>}
          </button>
          {show && (
            <div className={boxContainer} style={{ boxShadow: '0px 2px 8px #0000001A' }}>
              <div className={boxClassName}>
                <ul className={UlContainer}>
                  {displayList?.map((element) => (
                    <button
                      type="button"
                      key={element.id}
                      className={`${itemsClassName} ${buttonClassname}`}
                      onClick={() =>
                        dropdownFilter
                          ? handleFilter(`${element.id}`)
                          : handleClick(`${element.id}`)
                      }
                      id={`box-${id}`}
                      onMouseDown={() => (touch.current = true)}
                    >
                      {element.name}
                    </button>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
