import * as actionTypes from '../constants';
import { updateObject } from '../../utils';

const initialState = {
  quotations: null,
  inEmission: null,
  emitted: null,
  loading: false,
  loadingQuotations: false,
  loadingEmitted: false,
  loadingInEmission: false,
  linkForm: null,
  canceledQuotes: null,
  debugDocuments: null,
  tariff: null,
};

const setDebugLoading = (state, action) => {
  return updateObject(state, {
    loading: action.payload,
  });
};

const setDebugLoadingQuotations = (state, action) => {
  return updateObject(state, {
    loadingQuotations: action.payload,
  });
};

const setDebugLoadingEmitted = (state, action) => {
  return updateObject(state, {
    loadingEmitted: action.payload,
  });
};

const setDebugLoadingInEmission = (state, action) => {
  return updateObject(state, {
    loadingInEmission: action.payload,
  });
};

const setDebugQuotations = (state, action) => {
  return updateObject(state, {
    quotations: action.payload,
  });
};

const setDebugInEmission = (state, action) => {
  return updateObject(state, {
    inEmission: action.payload,
  });
};

const setDebugEmitted = (state, action) => {
  return updateObject(state, {
    emitted: action.payload,
  });
};

const setDebugCancelInEmission = (state, action) => {
  let updatedQuotationIndex = state.inEmission.findIndex((item) => item.id == action.payload);
  state.inEmission[updatedQuotationIndex].cancelled = true;

  return updateObject(state, {
    inEmission: state.inEmission,
  });
};

const setDebugCancelQuotation = (state, action) => {
  let updatedQuotationIndex = state.quotations.findIndex(
    (item) => item.ComencemosNumeroCotizacion == action.payload,
  );
  state.quotations[updatedQuotationIndex].cancelado = true;

  return updateObject(state, {
    quotations: state.quotations,
  });
};

const setDebugLinkForm = (state, action) => {
  return updateObject(state, {
    linkForm: action.payload,
  });
};
const setDebugCanceled = (state, action) => {
  return updateObject(state, {
    canceledQuotes: action.payload,
  });
};
const setDebugDocuments = (state, action) => {
  return updateObject(state, {
    debugDocuments: action.payload,
  });
};
const setTariff = (state, action) => {
  return updateObject(state, {
    tariff: action.payload,
  });
};

const debug = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_DEBUG_QUOTATIONS:
      return setDebugQuotations(state, action);
    case actionTypes.SET_DEBUG_INEMISSION:
      return setDebugInEmission(state, action);
    case actionTypes.SET_DEBUG_EMITTED:
      return setDebugEmitted(state, action);
    case actionTypes.SET_DEBUG_CANCEL_INEMISSION:
      return setDebugCancelInEmission(state, action);
    case actionTypes.SET_DEBUG_CANCEL_QUOTATION:
      return setDebugCancelQuotation(state, action);
    case actionTypes.SET_DEBUG_LOADING:
      return setDebugLoading(state, action);
    case actionTypes.SET_DEBUG_LOADING_EMITTED:
      return setDebugLoadingEmitted(state, action);
    case actionTypes.SET_DEBUG_LOADING_INEMISSION:
      return setDebugLoadingInEmission(state, action);
    case actionTypes.SET_DEBUG_LOADING_QUOTATIONS:
      return setDebugLoadingQuotations(state, action);
    case actionTypes.SET_DEBUG_LINK_FORM:
      return setDebugLinkForm(state, action);
    case actionTypes.SET_DEBUG_CANCELED:
      return setDebugCanceled(state, action);
    case actionTypes.SET_DEBUG_DOCUMENTS:
      return setDebugDocuments(state, action);
    case actionTypes.SET_DEBUG_TARIFF:
      return setTariff(state, action);
    case actionTypes.SET_DEBUG_RESET:
      return initialState;
    default:
      return state;
  }
};

export default debug;
