import { axiosApiInstance } from 'utils/serviceUtils';

const URL = process.env.REACT_APP_BACKEND_API_URL;

export const createNewEmission = (body) =>
  axiosApiInstance.post(`${URL}/emission/new-emission`, body);
export const validateIdentification = (body) =>
  axiosApiInstance.post(`${URL}/emission/validate-person`, body);
export const saveRoleInfo = (body) => axiosApiInstance.put(`${URL}/emission/update-emission`, body);
export const getVehicleEmission = (body) =>
  axiosApiInstance.post(`${URL}/emission/get-vehicle-info`, body);
export const getDashboardEmission = (body) =>
  axiosApiInstance.post(`${URL}/emission/dashboard`, body);
export const setVehicleInfo = (body) =>
  axiosApiInstance.post(`${URL}/emission/set-vehicle-info`, body);
export const getAccessories = () => axiosApiInstance.post(`${URL}/emission/get-accessories`);
export const getResumeEmissionData = (body) =>
  axiosApiInstance.post(`${URL}/emission/get-emission`, body);
export const getBase64PdfService = (body) =>
  axiosApiInstance.post(`${URL}/emission/printPDF`, body);
export const createInspection = (body) => axiosApiInstance.post(`${URL}/emission/inspection`, body);
export const createEmissionFV = (body) =>
  axiosApiInstance.post(`${URL}/emission/resume-linkage`, body);
export const getEmissionByID = (body) =>
  axiosApiInstance.post(`${URL}/emission/get-emission`, body);
export const getSummaryPurchase = (body) =>
  axiosApiInstance.post(`${URL}/emission/purchase-summary`, body);
export const setEmisionStep = (body) => axiosApiInstance.post(`${URL}/emission/nextStep`, body);
export const setExpiredForm = (body) =>
  axiosApiInstance.post(`${URL}/emission/getExpiredForm`, body);
export const quoteEmitted = (body) => axiosApiInstance.post(`${URL}/emission/emit`, body);
export const getFinancialEntities = () =>
  axiosApiInstance.get(`${URL}/emission/getEntitiesFinancial`);

export const getIspection = (body) =>
  axiosApiInstance.post(`${URL}/emission/get-inspection-info`, body);
