import { combineReducers } from 'redux';
import user from './renovations';
import quotation from './quatations';
import general from './general';
import search from './search';
import emission from './emission';
import debug from './debug';

const appReducers = combineReducers({
  user,
  quotation,
  general,
  search,
  emission,
  debug,
});

const rootReducer = (state, action) => {
  return appReducers(state, action);
};

export default rootReducer;
