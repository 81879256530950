/* eslint-disable sonarjs/no-duplicate-string */
import { FormControlLabel, makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { styled } from '@material-ui/core/styles';
import React from 'react';
import checked from '../../../assets/images/checked.svg';

const BpIcon = styled('span')(() => ({
  borderRadius: 1,
  width: 18,
  height: 18,

  boxShadow: `inset 0 0 0 1px rgba(16,22,26,.70), inset 0 -1px 0 rgba(16,22,26,.1)`,
  backgroundColor: '#FFF4F5',

  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
}));

const BpIconDos = styled('span')(() => ({
  borderRadius: 2,
  width: 18,
  height: 18,

  boxShadow: `inset 0 0 0 1px #E92243, inset 0 -1px 0 rgba(16,22,26,.1)`,

  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  '.Mui-disabled &': {
    backgroundColor: '#E6E6E6',
    boxShadow: 'none',
    border: '1px solid #707070',
    opacity: '1',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
  },
}));
const BpIconTres = styled('span')(() => ({
  borderRadius: 1,
  width: 18,
  height: 18,

  boxShadow: `inset 0 0 0 1px #707070, inset 0 -1px 0 rgba(16,22,26,.1)`,
  backgroundColor: '#FFF4F5',

  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
}));

const BpCheckedIcon = styled(BpIcon)(({ disabled }) => ({
  backgroundColor: '#ffffff',

  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    margin: disabled ? '1px 0.10px' : '3px 1.9px',
    backgroundImage: `url(${checked})`,
    backgroundRepeat: 'no-repeat',
    content: '""',
  },
  '.Mui-disabled &': {
    backgroundColor: '#E6E6E6',
    boxShadow: 'none',
    border: '1px solid #707070',
    opacity: '1',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  },
}));

function BpCheckbox(props) {
  const { labelStyles, checkSelected, disabled, noWarning, ...checkProps } = props;

  const Icon = noWarning ? BpIconTres : BpIconDos;

  const useStyles = makeStyles(() => ({
    root: {
      ...labelStyles,
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
  }));
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.root}
      sx={{ alignItems: 'flex-Start' }}
      control={
        <Checkbox
          className={classes.root}
          sx={{
            '&:hover': { backgroundColor: 'transparent !important' },
          }}
          disableRipple
          checkedIcon={<BpCheckedIcon disabled={disabled} />}
          icon={checkSelected ? <BpIcon /> : <Icon />}
          checked={checkSelected}
          disabled={disabled}
          inputProps={{ 'aria-label': 'Checkbox demo' }}
          {...checkProps}
        />
      }
      label={props.label}
    />
  );
}
const checkbox = ({
  onChange = (f) => f,
  checkSelected = false,
  label,
  labelStyles = {
    alignItems: 'flex-start',
    margin: '-5px',
  },
  noWarning,
  disabled,
}) => {
  return (
    <div>
      <BpCheckbox
        onChange={onChange}
        label={label}
        labelStyles={labelStyles}
        checkSelected={checkSelected}
        noWarning={noWarning}
        disabled={disabled}
      />
    </div>
  );
};

export default checkbox;
