import moment from 'moment';
import { uploadFile } from 'services';
import {
  createEmissionFV,
  createInspection,
  createNewEmission,
  getAccessories,
  getDashboardEmission,
  getEmissionByID,
  getResumeEmissionData,
  getSummaryPurchase,
  getIspection,
  getVehicleEmission,
  saveRoleInfo,
  setEmisionStep,
  setExpiredForm,
  setVehicleInfo,
  quoteEmitted,
  getFinancialEntities,
} from '../../services/emission';
import { EmissionFormBody, parseEmissionResponse } from '../../utils';
import * as actionTypes from '../constants';
import { setLoading } from './quotation';
import { DAYS_IN_PREVIOUS, DAYS_IN_YEAR } from 'utils/constans';

export const fetchInspection = (inpectionId) => (dispatch) => {
  dispatch(setLoadingEmission(true));
  getIspection(inpectionId)
    .then(({ data }) => {
      if (data.data.code && data.data.code === '404') {
        const resp = { ...data.data, status: 'PENDIENTECREACION' };
        dispatch(setStatusInspection({ data: { ...resp } }));
        return;
      }
      const approvalDateValidation15Days = moment().isBetween(
        data.data.approvalDate,
        moment(data.data.approvalDate).add(DAYS_IN_PREVIOUS, 'days'),
      );

      const generationDateValidation15Days = moment().isBetween(
        data.data.generationDate,
        moment(data.data.generationDate).add(DAYS_IN_PREVIOUS, 'days'),
      );

      const generationDateValidationYear = moment().isBetween(
        data.data.generationDate,
        moment(data.data.generationDate).add(DAYS_IN_YEAR, 'days'),
      );
      if (
        generationDateValidationYear === true &&
        (approvalDateValidation15Days === true || generationDateValidation15Days === true)
      ) {
        dispatch(setStatusInspection({ ...data }));
      } else {
        const resp = { status: 'PENDIENTECREACION' };
        dispatch(setStatusInspection({ data: { ...resp } }));
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      dispatch(setLoadingEmission(false));
    });
};

export const setStatusInspection = (data) => {
  return {
    type: actionTypes.SET_INSPECTION_STATUS,
    payload: data,
  };
};

export const setEmission = (data) => {
  return {
    type: actionTypes.SET_EMISSION,
    payload: data,
  };
};

export const setResetEmission = () => {
  return {
    type: actionTypes.SET_RESET_STATE_EMISSION,
  };
};

export const getEmissionById = (data) => {
  return {
    type: actionTypes.GET_EMISSION,
    payload: data,
  };
};

export const setLoadingEmission = (data) => {
  return {
    type: actionTypes.SET_LOADING_EMISSION,
    payload: data,
  };
};

export const setErrorEmission = (data) => {
  return {
    type: actionTypes.SET_ERROR_EMISSION,
    payload: data,
  };
};

export const setErrorInspectionEmission = (data) => {
  return {
    type: actionTypes.SET_ERROR_VEHICLE_INSPECTION,
    payload: data,
  };
};

export const setErrorEmissionFV = (data) => {
  return {
    type: actionTypes.SET_ERROR_EMISSION_FV,
    payload: data,
  };
};

export const setVehicleEmission = (data) => {
  return {
    type: actionTypes.SET_VEHICLE_EMISSION,
    payload: data,
  };
};

export const setInspectionEmission = (data) => {
  return {
    type: actionTypes.SET_VEHICLE_INSPECTION,
    payload: data,
  };
};

export const setEmissionFV = (data) => {
  return {
    type: actionTypes.SET_EMISSION_FV,
    payload: data,
  };
};

export const setInfoEmissionDashboard = (data, userData) => {
  return {
    type: actionTypes.SET_EMISSION_DASHBOARD,
    payload: { data, userData },
  };
};
export const setAccesoriesList = (data) => {
  return {
    type: actionTypes.SET_ACCESORIES_LIST,
    payload: data,
  };
};

export const setResumeRole = () => {
  return {
    type: actionTypes.SET_ROLE_RESUME,
  };
};

export const setPurchaseSummary = (data) => {
  return {
    type: actionTypes.SET_PURCHASE_SUMMARY,
    payload: data,
  };
};

export const setQuoteEmmited = (data) => {
  return {
    type: actionTypes.SET_QUOTE_EMITTED,
    payload: data,
  };
};

export const setGobackRole = () => {
  return {
    type: actionTypes.SET_ROLE_GOBACK,
  };
};

export const startEmission = (onSuccess) => (dispatch, getState) => {
  const idQuotation = getState().quotation.quotations.quotation.id;

  dispatch(setLoading(true));
  createNewEmission({ data: { ID: idQuotation } })
    .then(({ data }) => {
      dispatch(setEmission(data.data));
    })
    .catch(() => {
      dispatch(setErrorEmission({}));
    })
    .finally(() => {
      dispatch(setLoading(false));
      onSuccess();
    });
};

export const saveFormData = (emissionData, stopLoading) => (dispatch, getState) => {
  const emissionResponse = getState().emission.emissionResponse;
  const body = {
    ...emissionData,
  };
  dispatch(setLoadingEmission(true));
  dispatch(setEmission(body));
  saveRoleInfo({ data: body })
    .then(({ data }) => {
      const setEmissionData = {
        ...emissionResponse,
        ...data.data,
      };
      dispatch(setEmission(setEmissionData));
    })
    .catch((e) => console.log(e))
    .finally(() => stopLoading && dispatch(setLoadingEmission(false)));
};

export const getVehicleEmissionAction =
  (goForward = false) =>
  (dispatch, getState) => {
    dispatch(setLoadingEmission(true));
    const plate = getState().emission.emissionResponse.plate;
    getVehicleEmission({ data: { plate: plate } })
      .then(({ data }) => {
        dispatch(
          setVehicleEmission({
            ...data.data,
            datosVehiculoServicio: {
              VehiculoEmisionMotor: Boolean(data.data.VehiculoEmisionMotor),
              VehiculoEmisionChasis: Boolean(data.data.VehiculoEmisionChasis),
              VehiculoEmisionColor: Boolean(data.data.VehiculoEmisionColor),
            },
          }),
        );
        dispatch(setErrorEmission(null));
      })
      .catch((e) => dispatch(setErrorEmission({ failedService: true, status: e.response.status })))
      .finally(() => {
        dispatch(setLoadingEmission(false));
        goForward && goForward();
      });
  };

export const getDashboardEmissionAction =
  ({ filter, all = true, perPage = 50, next, nextPage, emitted = false }) =>
  (dispatch, getState) => {
    dispatch(setLoading(true));
    const oldState = getState();
    const body = {
      data: {
        all: all,
        perPage: perPage,
        filter: filter ? filter : undefined,
        next: next ? next : undefined,
        emitted: emitted,
        brokerKey: +oldState?.general?.agentRuc,
      },
    };
    getDashboardEmission(body)
      .then(({ data }) => {
        const newData = [
          ...(oldState?.emission?.emissionTable?.Items || []),
          ...(data?.data?.Items || []),
        ];
        next
          ? (() => {
              dispatch(
                setInfoEmissionDashboard({ ...data.data, Items: newData }, getState().user.user),
              );
              data.data && nextPage();
            })()
          : dispatch(setInfoEmissionDashboard({ Items: [], ...data.data }, getState().user.user));
      })
      .catch((e) => console.log('error', e))
      .finally(() => dispatch(setLoading(false)));
  };

export const getAccesoriesAction = () => (dispatch) => {
  getAccessories()
    .then(({ data }) => dispatch(setAccesoriesList(data.data)))
    .catch((e) =>
      dispatch(setErrorEmission({ failedAccesories: true, status: e.response.status })),
    );
};

export const setVehicleEmissionAction =
  (
    dataForm,
    policyCurrent,
    vehicleZerokm,
    nombreColor,
    documents,
    actionButton,
    emissionStep = null,
  ) =>
  async (dispatch, getState) => {
    dispatch(setLoadingEmission(true));
    const ID = getState().emission.emissionResponse.ID;
    const listAccesories = getState().emission.accesoriesList;
    const vehicleService = getState().emission?.vehicleInformation?.datosVehiculoServicio;
    const body = EmissionFormBody(
      dataForm,
      policyCurrent,
      vehicleZerokm,
      ID,
      vehicleService,
      nombreColor,
      listAccesories,
    );
    body.VehiculoEmisionAccesoriosTotal =
      getState().emission?.emissionResponse?.VehicleAccessoriesValue;
    try {
      if (documents.documentInspection?.Ruta)
        body.VehiculoEmisionCaratulaPoliza = documents.documentInspection;
      else if (policyCurrent && !vehicleZerokm)
        body.VehiculoEmisionCaratulaPoliza = await uploadFile(
          documents.documentInspection,
          '/emission',
          true,
        );
      if (documents.document0km1?.Ruta) body.VehiculoEmisionFactura = documents.document0km1;
      else if (vehicleZerokm) {
        body.VehiculoEmisionFactura = await uploadFile(documents.document0km1, '/emission', false);
      }
      if (documents.document0km2?.Ruta)
        body.VehiculoEmisionCartaConcesionario = documents.document0km2;
      else if (vehicleZerokm) {
        body.VehiculoEmisionCartaConcesionario = await uploadFile(
          documents.document0km2,
          '/emission',
          false,
        );
      }
    } catch (error) {
      console.log(error);
    }
    const responseStatus =
      body.VehiculoEmisionCaratulaPoliza?.Status ||
      body.VehiculoEmisionFactura?.Status ||
      body.VehiculoEmisionCartaConcesionario?.Status;
    console.log('policy current', policyCurrent, responseStatus);
    let insStatus = 'PENDIENTECREACION';
    if (policyCurrent || vehicleZerokm) {
      insStatus = 'NOREQUERIDO';
    }
    body.EmisionStep = emissionStep;
    dispatch(setVehicleEmission(dataForm));
    setVehicleInfo({ data: body })
      .then(() => {
        dispatch(
          getEmissionById({
            EmisionFVEstado: 'PENDIENTECREACION',
            EmisionInspeccionEstado: insStatus,
          }),
        );
        dispatch(setLoadingEmission(false));
        actionButton(responseStatus === 200 || (policyCurrent === false && !vehicleZerokm));
        dispatch(setErrorEmission(null));
      })
      .catch((e) => {
        dispatch(setErrorEmission({ failedServiceSaved: true, status: e.respose.status }));
        dispatch(setLoadingEmission(false));
      });
  };

export const ResumeEmissionAction = (id, goToEmission) => (dispatch) => {
  dispatch(setLoading(true));
  getResumeEmissionData({ data: { ID: id } })
    .then(async ({ data }) => {
      const { EmissToRedux, vehicleEmissRedux, resumeProccessRedux } = parseEmissionResponse(
        data.data,
      );
      const dataDays = data.data;
      const today = moment().format('MM-DD-YYYY');
      const dayExpired =
        dataDays?.EmisionFinalFVAseguradoFechaExpiracion ||
        dataDays?.EmisionFinalFVTomadorFechaVigencia;
      console.log('daysEx', dayExpired);
      const days = moment(dayExpired).diff(today, 'days');
      let reduxResumeProccess = resumeProccessRedux;
      console.log('days', days);
      if (days < 0 && dataDays.EmisionFVEstado === 'procesform') {
        const response = await setExpiredForm({ data: { ID: id } });
        const IsExpired = response.data.data.EmisionFVEstado;
        reduxResumeProccess = { ...resumeProccessRedux, EmisionFVEstado: IsExpired };
      }
      dispatch(setVehicleEmission(vehicleEmissRedux));
      dispatch(setEmission(EmissToRedux));
      dispatch(getEmissionById(reduxResumeProccess));
      goToEmission();
    })
    .catch((e) => console.log('error', e))
    .finally(() => {
      dispatch(setLoading(false));
    });
};

export const getEmission = (ID) => async (dispatch) => {
  dispatch(setLoadingEmission(true));
  getEmissionByID({ data: { ID: ID } })
    .then(({ data }) => {
      dispatch(getEmissionById(data.data));
    })
    .catch((e) =>
      dispatch(setErrorEmission({ failedServiceSaved: true, status: e.response.status })),
    )
    .finally(() => {
      dispatch(setLoadingEmission(false));
    });
};

export const createInspectionAction = (emissionResponse) => async (dispatch, getState) => {
  const { nameUser } = getState().user.user;
  dispatch(setLoadingEmission(true));
  createInspection({ data: { submissionId: emissionResponse.ID, BrokerNombre: nameUser } })
    .then(({ data: { data } }) => {
      dispatch(setErrorInspectionEmission(null));
      dispatch(setInspectionEmission(data));
    })
    .catch((e) =>
      dispatch(
        setErrorInspectionEmission({
          failedServiceSaved: true,
          status: e.response.status,
          date: moment().format('DD/MMM/YYYY HH:mm:ss'),
        }),
        console.log(e.response.status, 'status'),
      ),
    )
    .finally(() => {
      dispatch(setLoadingEmission(false));
    });
};

export const createEmissionFVandInspectionAction =
  (ID, getEmissionData) => async (dispatch, getState) => {
    dispatch(setLoadingEmission(true));
    const { nameUser } = getState().user.user;
    await Promise.allSettled([
      (getEmissionData
        ? getEmissionData?.EmisionFVEstado?.toLowerCase() === 'pendientecreacion'
        : true) &&
        createEmissionFV({
          data: {
            emissionId: ID,
            BrokerNombre: nameUser,
          },
        })
          .then(async ({ data }) => {
            dispatch(setErrorEmissionFV(null));
            const dataDays = data.data;
            let today = moment().format('MM-DD-YYYY');
            let dayExpired = new Date(dataDays?.FVformInsuredExpires);
            console.log(dataDays?.FVformInsuredExpires, 'fecha');
            let days = moment(dayExpired).diff(today, 'days');
            if (days < 0) {
              setExpiredForm({ data: { ID: ID } });
              data.data.EmisionFVEstado = 'expiredForm';
            }
            dispatch(setEmissionFV(data.data));
          })
          .catch((e) => {
            dispatch(
              setErrorEmissionFV({
                failedService: true,
                status: e.response.status,
                date: moment().format('DD/MMM/YYYY HH:mm:ss'),
              }),
            );
          }),
      (getEmissionData
        ? getEmissionData?.EmisionInspeccionEstado?.toLowerCase() === 'pendientecreacion'
        : true) &&
        createInspection({ data: { submissionId: ID, BrokerNombre: nameUser } })
          .then(({ data: { data } }) => {
            dispatch(setErrorInspectionEmission(null));
            dispatch(setInspectionEmission(data));
          })
          .catch((e) =>
            dispatch(
              setErrorInspectionEmission({
                failedServiceSaved: true,
                status: e.response.status,
                date: moment().format('DD/MMM/YYYY HH:mm:ss'),
              }),
            ),
          ),
    ]).finally(() => {
      dispatch(setLoadingEmission(false));
    });
  };

export const createEmissionFVAction = (ID) => async (dispatch, getState) => {
  dispatch(setLoadingEmission(true));
  const { nameUser } = getState().user.user;
  const body = {
    emissionId: ID,
    BrokerName: nameUser,
  };
  createEmissionFV({ data: body })
    .then(async ({ data }) => {
      dispatch(setErrorEmissionFV(null));
      dispatch(setEmissionFV(data.data));
    })
    .catch((e) =>
      dispatch(
        setErrorEmissionFV({
          failedServiceSaved: true,
          status: e.response.status,
          date: moment().format('DD/MMM/YYYY HH:mm:ss'),
        }),
      ),
    )
    .finally(() => {
      dispatch(setLoadingEmission(false));
    });
};

export const purchaseSummaryAction = () => (dispatch, getState) => {
  dispatch(setLoadingEmission(true));
  const id = getState().emission.emissionResponse.ID;
  getSummaryPurchase({ data: { ID: id } })
    .then(({ data }) => {
      dispatch(setErrorEmission(null));
      dispatch(setPurchaseSummary(data.data));
    })
    .catch((e) => dispatch(setErrorEmission({ summaryError: true, status: e.response.status })))
    .finally(() => {
      dispatch(setLoadingEmission(false));
    });
};

export const saveEmisionStepAction =
  (step, stopLoading = false) =>
  (dispatch, getState) => {
    dispatch(setLoadingEmission(true));
    const id = getState().emission.emissionResponse.ID;
    setEmisionStep({ data: { ID: id, EmisionStep: step } })
      .then((f) => f)
      .catch((e) => console.log('error', e))
      .finally(() => {
        !stopLoading && dispatch(setLoadingEmission(false));
      });
  };

export const quoteEmittedAction = (goForward) => (dispatch, getState) => {
  dispatch(setLoadingEmission(true));
  const id = getState().emission.emissionResponse.ID;
  quoteEmitted({
    data: {
      ID: id,
    },
  })
    .then(({ data }) => {
      dispatch(setErrorEmission(null));
      dispatch(setQuoteEmmited(data.data));
      goForward();
    })
    .catch((e) => dispatch(setErrorEmission({ emitedError: true, status: e.response.status })))
    .finally(() => {
      dispatch(setLoadingEmission(false));
    });
};

export const fetchFinancialEntities = () => async (dispatch) => {
  dispatch({ type: actionTypes.SET_LOADING_EMISSION, payload: true });
  try {
    const response = await getFinancialEntities();
    dispatch({ type: actionTypes.SET_FINANCIAL_ENTITIES, payload: response.data });
  } catch (error) {
    dispatch({ type: actionTypes.SET_ERROR_EMISSION, payload: error });
  } finally {
    dispatch({ type: actionTypes.SET_LOADING_EMISSION, payload: false });
  }
};

export const saveSelectedEntity = (entity) => {
  return {
    type: actionTypes.SET_SELECTED_ENTITY,
    payload: entity,
  };
};
