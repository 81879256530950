import { cellPhoneTypes } from 'models/types';

const parseFinancialEntity = (roleInfo) => {
  return {
    financialEntity: roleInfo.financialEntity,
    fullName: roleInfo.fullName,
  };
};

export const parseEmissionResponse = (data) => {
  const parseValue = (value) => {
    return value ? value : undefined;
  };
  const EmissToRedux = {
    ID: data.ID,
    EmisionStep: data.EmisionStep,
    TakerIsEqualToInsured: data.TomadorIgualAsegurado,
    ContratorDocId: data.ContratorDocId,
    CreatedAt: data.FechaCreacion,
    VehicleBrand: data.VehiculoMarca,
    VehicleModel: data.VehiculoModelo,
    VehicleIsNew: data.VehiculoNuevo,
    VehicleAccessoriesValue: data.VehiculoAccesoriosValor,
    VehicleHasAccessories: data.VehiculoAccesorios,
    BrokerEmail: data.EmailBroker,
    EmisionInspeccionEstado: data.EmisionInspeccionEstado,
    EmisionFVEstado: data.EmisionFVEstado,
    plate: data.VehiculoPlaca,
    roleResume: true,
    VehiculoGrupo: data.VehiculoGrupo,
    CostumersRole: data.RolesClientes.map((role) => parseRolesClients(role)),
    CoberturaPlan: data.CoberturaPlan,
    CoberturaTotalPrima: data.CoberturaTotalPrima,
    EmisionFinalConsultarInspResponse: data.EmisionFinalConsultarInspResponse,
  };

  const vehicleEmissRedux = {
    accessories: data?.VehiculoEmisionAccesorios || false,
    engine: data?.VehiculoEmisionMotor,
    chassis: data?.VehiculoEmisionChasis,
    color: data?.VehiculoEmisionColor,
    ClientEmail: parseValue(data?.VehiculoEmisionCorreoCliente),
    BrokerEmail: parseValue(data?.VehiculoEmisionCorreoBroker),
    additionalRemark: parseValue(data?.VehiculoEmisionObservaciones),
    InspectionContactPerson: parseValue(data?.VehiculoEmisionPersonaContacto),
    ContactCellphone: parseValue(data?.VehiculoEmisionTelefonoContacto),
    lastCompany: parseValue(data?.VehiculoEmisionCompaniaAnterior),
    dateLastCompany: parseValue(data?.VehiculoEmisionFinVigenciaCompaniaAnterior),
    BillDate: parseValue(data?.VehiculoEmisionFechaFactura),
    DateCarDealer: parseValue(data?.VehiculoEmisionFechaSalidaConcesionario),
    datosVehiculoServicio: data?.datosVehiculoServicio,
  };
  if (!data.VehiculoEmisionInspeccion) {
    vehicleEmissRedux.documentInspection =
      data.VehiculoEmisionCaratulaPoliza?.Ruta !== '' && data.VehiculoEmisionCaratulaPoliza;
    vehicleEmissRedux.VehiculoEmisionInspeccion = data.VehiculoEmisionInspeccion;
  }
  if (data.VehiculoEmisionNuevo) {
    vehicleEmissRedux.VehiculoEmisionFactura =
      data.VehiculoEmisionFactura?.Ruta !== '' && data.VehiculoEmisionFactura;
    vehicleEmissRedux.VehiculoEmisionCartaConcesionario =
      data.VehiculoEmisionCartaConcesionario?.Ruta !== '' && data.VehiculoEmisionCartaConcesionario;
  }

  const resumeProccessRedux = {
    codeFVContractor: data.codeFVContractor,
    codeFVInsured: data.codeFVInsured,
    EmisionFVEstado: data.EmisionFVEstado,
    EmisionInspeccionEstado: data.EmisionInspeccionEstado,
    EmisionFinalFVAseguradoFechaExpiracion: data.EmisionFinalFVAseguradoFechaExpiracion,
    EmisionFinalFVTomadorFechaExpiracion: data.EmisionFinalFVTomadorFechaVigencia,
  };

  return { EmissToRedux, vehicleEmissRedux, resumeProccessRedux };
};
export const parseRolesClients = (role) => {
  let parsedRoleInfo = {
    isInfoMissing: role.isInfoMissing,
    isModalOpen: role.isModalOpen,
    role: role.role,
    isError: role.isError,
    isSelected: role.isSelected,
    title: role.title,
    isShow: role.isShow,
    roleInfo: {},
  };
  const IDENTIFICATION_TYPES_BY_ID = {
    1: 'C',
    2: 'R',
    3: 'P',
  };
  const RUC_TYPES_CODES = {
    N: 'Natural',
    J: 'Juridico',
  };
  if (
    role.roleInfo.TipoIdentificacion === IDENTIFICATION_TYPES_BY_ID['1'] ||
    (role.roleInfo.TipoIdentificacion === IDENTIFICATION_TYPES_BY_ID['2'] &&
      role.roleInfo?.ClienteRucTipo === RUC_TYPES_CODES.N) ||
    role.roleInfo.TipoIdentificacion === IDENTIFICATION_TYPES_BY_ID['3']
  ) {
    parsedRoleInfo = {
      ...parsedRoleInfo,
      roleInfo: parsePersonRole(role.roleInfo),
    };
  } else if (
    role.roleInfo.TipoIdentificacion === IDENTIFICATION_TYPES_BY_ID['2'] &&
    role.roleInfo.ClienteRucTipo === RUC_TYPES_CODES.J
  ) {
    parsedRoleInfo = {
      ...parsedRoleInfo,
      roleInfo: parseBusinessRole(role.roleInfo),
    };
  }
  if (role.role == 'beneficiario_acreedor') {
    parsedRoleInfo = {
      ...parsedRoleInfo,
      roleInfo: parseFinancialEntity(role.roleInfo),
    };
  }

  return parsedRoleInfo;
};
export const parsePersonRole = (role) => {
  return {
    identificationType: role.TipoIdentificacion,
    identificationNumber: role.Identificacion,
    firstName: role.PrimerNombre,
    secondName: role.SegundoNombre,
    surname: role.PrimerApellido,
    secondSurname: role.SegundoApellido,
    fullName: role.NombreCompleto,
    maritalStatus: role.EstadoCivil,
    gender: role.Genero,
    birthDate: role.FechaNacimiento,
    nationality: role.Nacionalidad,
    province: role.ClienteLugarMovilizacion,

    address: role.DireccionTexto,
    addressType: role.DireccionTipoCod,
    addressList: role.ClienteDirecciones,

    cellPhoneType: cellPhoneTypes.find(({ code }) => code == role.TelefonoCod)?.name || '',
    phoneNumber: role.ClienteCelularFV,
    phoneNumberConfirm: role.ClienteCelularConfirmacionFV,
    phoneNumberList: role.ClienteCelularesFV,

    email: role.ClienteCorreoFV,
    emailConfirm: role.ClienteCorreoConfirmacionFV,
    emailList: role.ClienteCorreosFV,

    Province: role.ClienteLugarMovilizacion,
  };
};
const parseBusinessRole = (role) => {
  return {
    rucType: role.ClienteRucTipo,
    identificationType: role.TipoIdentificacion,
    identificationNumber: role.Identificacion,
    businessName: role.RazonSocial,
    constitutionDate: role.FechaConstitucion,
    province: role.ClienteLugarMovilizacion,

    cellPhoneType: cellPhoneTypes.find(({ code }) => code == role.TelefonoCod)?.name || 'Celular',
    phoneNumber: role.ClienteCelularFV,
    phoneNumberConfirm: role.ClienteCelularConfirmacionFV,
    phoneNumberList: role.ClienteCelularesFV,

    email: role.ClienteCorreoFV,
    emailConfirm: role.ClienteCorreoConfirmacionFV,
    emailList: role.ClienteCorreosFV,

    Province: role.ClienteLugarMovilizacion,

    addressList: role.ClienteDirecciones,
    address: role.DireccionTexto,
    addressType: role.DireccionTipoCod,
  };
};
