import * as actionTypes from '../constants';
import { parseDashboardItems, updateObject } from '../../utils';

const initialState = {
  loading: false,
  loadingUser: false,
  error: undefined,
  quotations: null,
  quotationsRequest: null,
  infoVehicle: null,
  infoPerson: null,
  infoDriver: null,
  personPage: null,
  coverage: null,
  coverageRuleId: null,
  dashboardquote: null,
  dashboardquoteItems: null,
  dashboardCount: {},
};

const setResetState = () => {
  return {
    ...initialState,
  };
};

const setDashboardCount = (state, action) => {
  return updateObject(state, {
    dashboardCount: action.payload,
  });
};

const setError = (state, action) => {
  return updateObject(state, {
    error: action.payload,
  });
};
const setInfoVehicle = (state, action) => {
  return updateObject(state, {
    infoVehicle: action.payload,
  });
};

const setPerson = (state, action) => {
  return updateObject(state, {
    infoPerson: action.payload,
  });
};

const setDriver = (state, action) => {
  return updateObject(state, {
    infoDriver: action.payload,
  });
};

const setLoading = (state, action) => {
  return updateObject(state, {
    loading: action.payload,
  });
};

const setLoadingUser = (state, action) => {
  return updateObject(state, {
    loadingUser: action.payload,
  });
};

const setQuotations = (state, action) => {
  return updateObject(state, {
    quotations: action.payload,
  });
};

const setQuotationsRequest = (state, action) => {
  return updateObject(state, {
    quotationsRequest: action.payload,
  });
};

const setPersonInfoPage = (state, action) => {
  return updateObject(state, {
    personPage: action.payload,
  });
};

const setCoveragesQuote = (state, action) => {
  return updateObject(state, {
    coverage: action.payload,
  });
};

const setRuleIdCoverage = (state, action) => {
  return updateObject(state, {
    coverageRuleId: action.payload,
  });
};

const setInfoDashboard = (state, action) => {
  return updateObject(state, {
    dashboardquoteItems: parseDashboardItems(action.payload.Items),
    dashboardquote: action.payload,
  });
};

const quatations = (state = initialState, action = {}) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case actionTypes.SET_QUOTATION:
      return setQuotations(state, action);
    case actionTypes.SET_QUOTATION_REQUEST:
      return setQuotationsRequest(state, action);
    case actionTypes.SET_LOADING_QUATATIONS:
      return setLoading(state, action);
    case actionTypes.SET_LOADING_USER:
      return setLoadingUser(state, action);
    case actionTypes.SET_ERROR_QUATATION:
      return setError(state, action);
    case actionTypes.SET_INFO_VEHICULO:
      return setInfoVehicle(state, action);
    case actionTypes.SET_INFO_PERSON:
      return setPerson(state, action);
    case actionTypes.SET_INFO_DRIVER:
      return setDriver(state, action);
    case actionTypes.SET_PERSON_PAGE:
      return setPersonInfoPage(state, action);
    case actionTypes.SET_COVERAGE:
      return setCoveragesQuote(state, action);
    case actionTypes.SET_COVERAGE_RULEID:
      return setRuleIdCoverage(state, action);
    case actionTypes.SET_DASHBOARD_QUOTE:
      return setInfoDashboard(state, action);
    case actionTypes.SET_RESET_STATE_QUOTE:
      return setResetState();
    case actionTypes.SET_DASHBOARD_COUNT:
      return setDashboardCount(state, action);
    default:
      return state;
  }
};

export default quatations;
