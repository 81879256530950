import { getCoverageAndAssistance, saveDataCoverages } from '../../services/quotation';
import { setError, setLoading } from './quotation';
import * as actionTypes from '../constants';

export const setCoverage = (data) => {
  return {
    type: actionTypes.SET_COVERAGE,
    payload: data,
  };
};

export const setCoverageRuleId = (data) => {
  return {
    type: actionTypes.SET_COVERAGE_RULEID,
    payload: data,
  };
};

export const getCoverageAction = (id, goNext) => (dispatch) => {
  dispatch(setLoading(true));
  getCoverageAndAssistance(id)
    .then(({ data }) => {
      dispatch(setCoverage(data.data));
      console.log('----- CoberturaRuleId on GetQuotation------');
      console.log(data.data.CoberturaRuleId);

      const _ruleIdFactors = {
        CoberturaRuleId: data.data.CoberturaRuleId,
      };
      dispatch(setCoverageRuleId(_ruleIdFactors));
      dispatch(setError(null));
    })
    .catch((e) => {
      if (e?.response?.data?.data?.code === '2') {
        dispatch(
          setError({
            noCoverages: true,
            status: e?.response?.status,
            codError: 2,
            isUpdating: false,
          }),
        );

        return;
      }
      dispatch(
        setError({
          noCoverages: true,
          status: e?.response?.status || 500,
          codError: 1,
          isUpdating: false,
        }),
      );
    })
    .finally(() => {
      dispatch(setLoading(false));
      goNext && goNext();
    });
};

export const saveCoveragesAction =
  ({ body, id, goHome, goBack, InitiateEmission, retarificar }) =>
  (dispatch, getState) => {
    dispatch(setLoading(true));
    console.log('body', body);

    const { CoberturaRuleId = '' } = getState().quotation.coverageRuleId;
    console.log(CoberturaRuleId);
    saveDataCoverages({ data: { ...body, retarificar, CoberturaRuleId } }, id) //CoberturaRuleId dentro de data
      .then((r) => {
        console.log(r.data);
        dispatch(setCoverage({ plans: r.data.data.plans }));
        goHome && goHome();
        goBack && goBack();
        InitiateEmission && InitiateEmission();
      })
      .catch((e) => {
        console.log(e?.response);
        if (
          e?.response?.data?.data?.code === '2' ||
          e?.response?.status === 502 ||
          e?.response?.status === 501
        ) {
          dispatch(
            setError({
              noCoverages: true,
              status: e?.response?.status,
              codError: 2,
              isUpdating: true,
            }),
          );
        }
        InitiateEmission && dispatch(setLoading(false));
      })
      .finally(() => {
        !InitiateEmission && dispatch(setLoading(false));
      });
  };
