export const documentsTypes = [
  { id: 1, name: 'Cédula' },
  { id: 2, name: 'RUC' },
  { id: 3, name: 'Pasaporte' },
];

export const documentTypesByID = {
  1: 'Cédula',
  2: 'RUC',
  3: 'Pasaporte',
};

export const cellPhoneTypes = [
  {
    code: 2,
    key: 1,
    name: 'Celular',
  },
  { code: 4, name: 'Oficina', key: 2 },
];

export const documentTypes = {
  C: { id: 'C', type: '1', name: 'Cédula', min: 10, max: 10 },
  P: { id: 'P', type: '3', name: 'Pasaporte', min: 3, max: 20 },
};

export const documentTypesEmission = {
  C: { id: 'C', type: '1', name: 'Cédula', min: 10, max: 10 },
  R: { id: 'R', type: '2', name: 'RUC', min: 13, max: 13 },
  P: { id: 'P', type: '3', name: 'Pasaporte', min: 3, max: 20 },
};

export const plateTypes = [
  { id: 4, name: 'PARTICULAR' },
  { id: 6, name: 'RAMV' },
];

export const PDF_TYPES = {
  EMISSION: 'EMISSION',
  COVERAGE_PROVISIONAL: 'COVERAGE_PROVISIONAL',
};

export const NAMES_PDF = {
  COVERAGE_PROVISIONAL: 'CERTIFICADO DE COBERTURA PROVICIONAL',
};

export const CarTypes = [
  {
    id: 3,
    name: 'Particular ',
    des: 'Vehículo de servicio comercial o particular, utilizado para el transporte de materiales, mercancías o personas para cumplir con su actividad económica. Excluye vehículos de uso público, taxi, taxi amigo, taxi ejecutivo y vehículos para renta.',
  },
  {
    id: 5,
    name: 'Comercial',
    des: 'Vehículo de servicio particular, para transporte de personas sin fines lucrativos',
  },
];

export const activityTypes = [
  { id: 523, name: 'A - AGRICULTURA, GANADERÍA, SILVICULTURA Y PESCA.' },
  { id: 524, name: 'B - EXPLOTACIÓN DE MINAS Y CANTERAS.' },
  { id: 525, name: 'C - INDUSTRIAS MANUFACTURERAS.' },
  { id: 526, name: 'D - SUMINISTRO DE ELECTRICIDAD, GAS, VAPOR Y AIRE ACONDICIONADO.' },
  {
    id: 527,
    name: 'E - DISTRIBUCIÓN DE AGUA; ALCANTARILLADO, GESTIÓN DE DESECHOS Y ACTIVIDADES DE SANEAMIENTO.',
  },
  { id: 528, name: 'F - CONSTRUCCIÓN.' },
  { id: 529, name: 'G - COMERCIO AL POR MAYOR Y AL POR MENOR REPARACIÓN DE VEHÍCULOS' },
  { id: 530, name: 'H - TRANSPORTE Y ALMACENAMIENTO.' },
  { id: 531, name: 'I - ACTIVIDADES DE ALOJAMIENTO Y DE SERVICIO DE COMIDAS.' },
  { id: 532, name: 'J - INFORMACIÓN Y COMUNICACIÓN.' },
  { id: 533, name: 'K - ACTIVIDADES FINANCIERAS Y DE SEGUROS.' },
  { id: 534, name: 'L - ACTIVIDADES INMOBILIARIAS.' },
  { id: 535, name: 'M - ACTIVIDADES PROFESIONALES, CIENTÍFICAS Y TÉCNICAS.' },
  { id: 536, name: 'N - ACTIVIDADES DE SERVICIOS ADMINISTRATIVOS Y DE APOYO.' },
  { id: 537, name: 'P - ENSEÑANZA.' },
  { id: 538, name: 'Q - ACTIVIDADES DE ATENCIÓN DE LA SALUD HUMANA Y DE ASISTENCIA SOCIAL.' },
  { id: 539, name: 'R - ARTES, ENTRETENIMIENTO Y RECREACIÓN.' },
  { id: 540, name: 'S - OTRAS ACTIVIDADES DE SERVICIOS.' },
  { id: 541, name: 'T - ACTIVIDADES DE LOS HOGARES COMO EMPLEADORES' },
];

export const MaritalStatusTypes = [
  { id: 1, name: 'SOLTERO' },
  { id: 2, name: 'CASADO' },
  { id: 3, name: 'ÚNION LIBRE' },
  { id: 4, name: 'DIVORCIADO' },
  { id: 5, name: 'VIUDO' },
];

export const AdressTypes = [{ id: 13, name: 'Correspondencia' }];

export const filterTypesDashboard = [
  { id: 1, name: 'ID' },
  { id: 2, name: 'Placa' },
  { id: 3, name: 'Póliza' },
];

export const vehicleColor = [
  { id: 33, name: 'PLATA' },
  { id: 37, name: 'TURQUESA' },
  { id: 89, name: 'VIOLETA' },
  { id: 426, name: 'LILA' },
  { id: 48, name: 'TOMATE' },
  { id: 234, name: 'ROSA' },
  { id: 249, name: 'COBRE' },
  { id: 9, name: 'CELESTE' },
  { id: 171, name: 'CREMA' },
  { id: 4, name: 'ROJO' },
  { id: 5, name: 'NARANJA' },
  { id: 7, name: 'MORADO' },
  { id: 12, name: 'CAFE' },
  { id: 14, name: 'NEGRO' },
  { id: 3, name: 'AMARILLO' },
  { id: 41, name: 'PLOMO' },
  { id: 145, name: 'BRONCE' },
  { id: 2, name: 'AZUL' },
  { id: 13, name: 'BLANCO' },
  { id: 17, name: 'DORADO' },
  { id: 31, name: 'GRIS' },
  { id: 16, name: 'BEIGE' },
  { id: 32, name: 'VINO' },
  { id: 40, name: 'MARRON' },
  { id: 18, name: 'PLATEADO' },
  { id: 192, name: 'RUBY' },
  { id: 205, name: 'MARFIL' },
  { id: 10, name: 'ROSADO' },
  { id: 458, name: 'VERDE' },
];
export const lastCompanies = [
  { id: 16, name: 'AIG METROPOLITANA' },
  { id: 55, name: 'ASEGURADORA DEL SUR' },
  { id: 28, name: 'CHUBB SEGUROS ECUADOR S.A.' },
  { id: 4, name: 'SEGUROS CONDOR' },
  { id: 23, name: 'ECUATORIANO SUIZA' },
  { id: 17, name: 'SEGUROS EQUINOCCIAL' },
  { id: 52, name: 'GENERALI' },
  { id: 48, name: 'HISPANA' },
  { id: 40, name: 'INTEROCEANICA C.A. DE SEGUROS.' },
  { id: 14, name: 'LA UNION' },
  { id: 53, name: 'LATINA SEGUROS C.A.' },
  { id: 3, name: 'MAPFRE ATLAS' },
  { id: 5, name: 'ORIENTE SEGUROS S.A.' },
  { id: 1, name: 'SEGUROS ALIANZA S.A.' },
  { id: 24, name: 'SEGUROS SUCRE S.A.' },
  { id: 29, name: 'SEGUROS UNIDOS' },
  { id: 37, name: 'ZURICH SEGUROS ECUADOR S.A.' },
  { id: 56, name: 'SEGUROS COLON' },
  { id: 57, name: 'SEGUROS CONSTITUCION' },
  { id: 58, name: 'SWEADEN COMPAÑÍA DE SEGUROS S.A.' },
  { id: 59, name: 'VAZSEGUROS S.A. COMPAÑÍA DE SEGUROS' },
  { id: 60, name: 'AMASEGUROS' },
];

export const nationalities = [
  { id: 1, name: 'AFGANA' },
  { id: 2, name: 'ALBANES' },
  { id: 3, name: 'ALEMANA' },
  { id: 4, name: 'ANDORRANA' },
  { id: 5, name: 'ANGOLEÑA' },
  { id: 6, name: 'ANTIGUANA' },
  { id: 7, name: 'HOLANDESA' },
  { id: 8, name: 'APATRIDA' },
  { id: 9, name: 'SAUDITA' },
  { id: 10, name: 'ARGELINA' },
  { id: 11, name: 'ARGENTINA' },
  { id: 12, name: 'ARMENIA' },
  { id: 13, name: 'ARUBEÑA' },
  { id: 14, name: 'AUSTRALIANA' },
  { id: 15, name: 'AUSTRIACA' },
  { id: 16, name: 'AZERBAIYANA' },
  { id: 17, name: 'BAHAMEÑA' },
  { id: 18, name: 'BAREINI' },
  { id: 19, name: 'BANGLADESI' },
  { id: 20, name: 'BARBADENSE' },
  { id: 21, name: 'BELGA' },
  { id: 22, name: 'BELICEÑA' },
  { id: 23, name: 'BENINES' },
  { id: 24, name: 'BERMUDEÑA' },
  { id: 25, name: 'BIELORRUSA' },
  { id: 26, name: 'BIRMANA' },
  { id: 27, name: 'BOFUTATSUANES' },
  { id: 28, name: 'BOLIVIANA' },
  { id: 29, name: 'BOSNIOHERZEGOVINA' },
  { id: 30, name: 'BOTSUANESA' },
  { id: 31, name: 'BRASILEÑA' },
  { id: 32, name: 'BRITANICA / ESCOCESA' },
  { id: 33, name: 'BRUNEANA' },
  { id: 34, name: 'BULGARA' },
  { id: 35, name: 'BURKINES' },
  { id: 36, name: 'BURUNDES' },
  { id: 37, name: 'BUTANES' },
  { id: 38, name: 'CABOVERDIANA' },
  { id: 39, name: 'CAMBOYANA' },
  { id: 40, name: 'CAMERUNES' },
  { id: 41, name: 'CANADIENSE' },
  { id: 42, name: 'CHADIANA' },
  { id: 43, name: 'CHILENA' },
  { id: 44, name: 'CHINA' },
  { id: 45, name: 'CHIPRIOTA' },
  { id: 46, name: 'CISKEINA' },
  { id: 47, name: 'VATICANA' },
  { id: 48, name: 'COLOMBIANA' },
  { id: 49, name: 'COMORENSE' },
  { id: 50, name: 'CONGOLEÑA' },
  { id: 51, name: 'SURCOREANA' },
  { id: 52, name: 'MARFILEÑA' },
  { id: 53, name: 'COSTARRICENSE' },
  { id: 54, name: 'CROATA' },
  { id: 55, name: 'CUBANA' },
  { id: 56, name: 'CUBANA/ESPAÑOLA' },
  { id: 57, name: 'DANESA' },
  { id: 58, name: 'DOMINIQUESA' },
  { id: 59, name: 'ECUATORIANA' },
  { id: 60, name: 'ECUADOR / COREA SUR*' },
  { id: 61, name: 'ECUATORIANA / ALEMANA' },
  { id: 62, name: 'ECUATORIANA / ARGENTINA' },
  { id: 63, name: 'ECUATORIANA / ARMENIA' },
  { id: 64, name: 'ECUATORIANA / AUSTRIACA' },
  { id: 65, name: 'ECUATORIANA / BELGA' },
  { id: 66, name: 'ECUATORIANA / BIELORRUSA' },
  { id: 67, name: 'ECUATORIANA / BOLIVIANA' },
  { id: 68, name: 'ECUATORIANA / BRASILEÑA' },
  { id: 69, name: 'ECUATORIANA / BRITANICA' },
  { id: 70, name: 'ECUATORIANA / BULGARA' },
  { id: 71, name: 'ECUATORIANA / CANADIENSE' },
  { id: 72, name: 'ECUATORIANA / CHECA' },
  { id: 73, name: 'ECUATORIANA / CHILENA' },
  { id: 74, name: 'ECUATORIANA / CHINA' },
  { id: 75, name: 'ECUATORIANA / COLOMBIANA' },
  { id: 76, name: 'ECUATORIANA / CUBANA' },
  { id: 77, name: 'ECUATORIANA / DOMINICANA' },
  { id: 78, name: 'ECUATORIANA / ESPAÑOLA' },
  { id: 79, name: 'ECUATORIANA / FINLANDESA' },
  { id: 80, name: 'ECUATORIANA / FRANCESA' },
  { id: 81, name: 'ECUATORIANA / GRIEGA' },
  { id: 82, name: 'ECUATORIANA / HOLANDESA' },
  { id: 83, name: 'ECUATORIANA / HONDUREÑA' },
  { id: 84, name: 'ECUATORIANA / HUNGARA' },
  { id: 85, name: 'ECUATORIANA / INGLESA' },
  { id: 86, name: 'ECUATORIANA / IRANI' },
  { id: 87, name: 'ECUATORIANA / ITALIANA' },
  { id: 88, name: 'ECUATORIANA / MEXICANA' },
  { id: 89, name: 'ECUATORIANA / NORUEGA' },
  { id: 90, name: 'ECUATORIANA / PANAMEÑA' },
  { id: 91, name: 'ECUATORIANA / PERUANA' },
  { id: 92, name: 'ECUATORIANA / POLACA' },
  { id: 93, name: 'ECUATORIANA / RUMANA' },
  { id: 94, name: 'ECUATORIANA / RUSA' },
  { id: 95, name: 'ECUATORIANA / SUECA' },
  { id: 96, name: 'ECUATORIANA / SUIZA' },
  { id: 97, name: 'ECUATORIANA / UCRANIANA' },
  { id: 98, name: 'ECUATORIANA / URUGUAYA' },
  { id: 99, name: 'ECUATORIANA / VENEZOLANA' },
  { id: 100, name: 'ECUATORIANA/AUSTRALIANA' },
  { id: 101, name: 'ECUATORIANA/COSTARRICENS' },
  { id: 102, name: 'ECUATORIANA/ESTADOUNIDE.' },
  { id: 103, name: 'ECUATORIANA/PORTUGUESA' },
  { id: 104, name: 'ECUATORIANA/SALVADOREÑA' },
  { id: 105, name: 'EGIPCIA' },
  { id: 106, name: 'SALVADOREÑA' },
  { id: 107, name: 'EMIRATENSE' },
  { id: 108, name: 'ERITREA' },
  { id: 109, name: 'ESCOCESA' },
  { id: 110, name: 'ESLOVACA' },
  { id: 111, name: 'ESLOVENA' },
  { id: 112, name: 'ESPAÑOLA' },
  { id: 113, name: 'ESTADOUNIDENSE' },
  { id: 114, name: 'ESTONIA' },
  { id: 115, name: 'ETIOPE' },
  { id: 116, name: 'RUSA' },
  { id: 117, name: 'FILIPINA' },
  { id: 118, name: 'FINLANDES' },
  { id: 119, name: 'FIYIANA' },
  { id: 120, name: 'FRANCESA' },
  { id: 121, name: 'GABONES' },
  { id: 122, name: 'GAMBIANA' },
  { id: 123, name: 'GEORGIANA' },
  { id: 124, name: 'GHANES' },
  { id: 125, name: 'GRANADINA' },
  { id: 126, name: 'GRIEGA' },
  { id: 127, name: 'GUATEMALTECA' },
  { id: 128, name: 'GUINEANA' },
  { id: 129, name: 'BISSAUGUINEANA' },
  { id: 130, name: 'ECUATOGUINEANA' },
  { id: 131, name: 'GUYANES' },
  { id: 132, name: 'HAITIANA' },
  { id: 133, name: 'HOLANDESA' },
  { id: 134, name: 'HONDUREÑA' },
  { id: 135, name: 'HONG KONG' },
  { id: 136, name: 'HUNGARA' },
  { id: 137, name: 'INDIA' },
  { id: 138, name: 'INDONESIA' },
  { id: 139, name: 'IRANI' },
  { id: 140, name: 'IRAQUI' },
  { id: 141, name: 'IRLANDES' },
  { id: 142, name: 'ISLANDESA' },
  { id: 143, name: 'MARSHALES' },
  { id: 144, name: 'SALOMONENSE' },
  { id: 145, name: 'ISRAELI' },
  { id: 146, name: 'ITALIANA' },
  { id: 147, name: 'ITALIANA/BRASILERA' },
  { id: 148, name: 'JAMAIQUINA' },
  { id: 149, name: 'JAPONESA' },
  { id: 150, name: 'JORDANA' },
  { id: 151, name: 'KAZAJA' },
  { id: 152, name: 'KENIANA' },
  { id: 153, name: 'KIRGUIS' },
  { id: 154, name: 'KIRIBATIANA' },
  { id: 155, name: 'KOSOVAR' },
  { id: 156, name: 'KUWAITI' },
  { id: 157, name: 'LAOSIANA' },
  { id: 158, name: 'LESOTENSE' },
  { id: 159, name: 'LETON' },
  { id: 160, name: 'LIBANESA' },
  { id: 161, name: 'LIBERIANA' },
  { id: 162, name: 'LIBIA' },
  { id: 163, name: 'LIECHTENSTEINIANA' },
  { id: 164, name: 'LITUANA' },
  { id: 165, name: 'LUXEMBURGUES' },
  { id: 166, name: 'MACEDONIA' },
  { id: 167, name: 'MALGACHE' },
  { id: 168, name: 'MALASIA' },
  { id: 169, name: 'MALAUI' },
  { id: 170, name: 'MALDIVA' },
  { id: 171, name: 'MALIENSE' },
  { id: 172, name: 'MALTES' },
  { id: 173, name: 'MARROQUI' },
  { id: 174, name: 'MAURICIANA' },
  { id: 175, name: 'MAURITANA' },
  { id: 176, name: 'MEXICANA' },
  { id: 177, name: 'MICRONESIA' },
  { id: 178, name: 'MOLDAVA' },
  { id: 179, name: 'MONEGASCA' },
  { id: 180, name: 'MONGOLA' },
  { id: 181, name: 'MONTENEGRINO' },
  { id: 182, name: 'MOZAMBIQUEÑA' },
  { id: 183, name: 'NAMIBIANA' },
  { id: 184, name: 'NAURUANA' },
  { id: 185, name: 'NEPALES' },
  { id: 186, name: 'NICARAGUENSE' },
  { id: 187, name: 'NIGERINA' },
  { id: 188, name: 'NIGERIANA' },
  { id: 189, name: 'NORCOREANA' },
  { id: 190, name: 'NORUEGA' },
  { id: 191, name: 'NEOZELANDES' },
  { id: 192, name: 'OMANI' },
  { id: 193, name: 'PAQUISTANI' },
  { id: 194, name: 'PALAUNA' },
  { id: 195, name: 'PALESTINA' },
  { id: 196, name: 'PALESTINA' },
  { id: 197, name: 'PANAMEÑA' },
  { id: 198, name: 'PAPU NEOGUINEANA' },
  { id: 199, name: 'PARAGUAYA' },
  { id: 200, name: 'PERUANA' },
  { id: 201, name: 'POLACA' },
  { id: 202, name: 'PORTUGUES' },
  { id: 203, name: 'PUERTORRIQUENO' },
  { id: 204, name: 'QATARI' },
  { id: 205, name: 'BRITANICA' },
  { id: 206, name: 'BRITANICA / INGLESA' },
  { id: 207, name: 'CENTROAFRICANA' },
  { id: 208, name: 'CHECA' },
  { id: 209, name: 'TAIWANESA' },
  { id: 210, name: 'SUDAFRICANA' },
  { id: 211, name: 'CONGOLEÑA' },
  { id: 212, name: 'DOMINICANA' },
  { id: 213, name: 'DOMINICANA/ESPAÑOLA' },
  { id: 214, name: 'RUANDESA' },
  { id: 215, name: 'RUMANA' },
  { id: 216, name: 'SAHARAUI' },
  { id: 217, name: 'SAMOANA' },
  { id: 218, name: 'SANCRISTOBALEÑA' },
  { id: 219, name: 'SANMARINENSE' },
  { id: 220, name: 'SANVICENTINA' },
  { id: 221, name: 'SANTALUCENSE' },
  { id: 222, name: 'SANTOTOMENSE' },
  { id: 223, name: 'SENEGALESA' },
  { id: 224, name: 'SERBIA' },
  { id: 225, name: 'SEYCHELLENSE' },
  { id: 226, name: 'SIERRALEONES' },
  { id: 227, name: 'SINGAPURENSE' },
  { id: 228, name: 'SIRIA' },
  { id: 229, name: 'SOMALI' },
  { id: 230, name: 'ESRILANQUESA' },
  { id: 231, name: 'SRI LANKA' },
  { id: 232, name: 'SUAZI' },
  { id: 233, name: 'SUDANES' },
  { id: 234, name: 'SUECA' },
  { id: 235, name: 'SUIZA' },
  { id: 236, name: 'SURINAMES' },
  { id: 237, name: 'TAILANDESA' },
  { id: 238, name: 'TANZANA' },
  { id: 239, name: 'TAYIKA' },
  { id: 240, name: 'TOGOLESA' },
  { id: 241, name: 'TONGANA' },
  { id: 242, name: 'TRANSKEINA' },
  { id: 243, name: 'TRINITENSE' },
  { id: 244, name: 'TUNECINA' },
  { id: 245, name: 'TURCOMANA' },
  { id: 246, name: 'TURCA' },
  { id: 247, name: 'TUVALUANA' },
  { id: 248, name: 'UCRANIANA' },
  { id: 249, name: 'UGANDESA' },
  { id: 250, name: 'URUGUAYA' },
  { id: 251, name: 'UZBEKA' },
  { id: 252, name: 'VUNUATENSE' },
  { id: 253, name: 'VENDA' },
  { id: 254, name: 'VENEZOLANA' },
  { id: 255, name: 'VIETNAMITA' },
  { id: 256, name: 'YEMENI' },
  { id: 257, name: 'YEMENI ARABE' },
  { id: 258, name: 'YIBUTIANA' },
  { id: 259, name: 'YUGOSLAVA' },
  { id: 260, name: 'ZAIRENO' },
  { id: 261, name: 'ZAMBIANA' },
  { id: 262, name: 'ZIMBABUENSE' },
];

export const listAccesory = [
  { id: 1, vehicle: 'LIVIANO', name: 'AIRE ACONDICIONADO' },
  { id: 2, vehicle: 'LIVIANO', name: 'ALARMA' },
  { id: 3, vehicle: 'LIVIANO', name: 'ALERONES' },
  { id: 4, vehicle: 'LIVIANO', name: 'AMPLIFICADORES Y ECUALIZADORES' },
  { id: 5, vehicle: 'LIVIANO', name: 'AROS' },
  { id: 6, vehicle: 'LIVIANO', name: 'AROS Y LLANTAS' },
  { id: 7, vehicle: 'LIVIANO', name: 'BARRA ESTABILIZADORA' },
  { id: 8, vehicle: 'LIVIANO', name: 'BOLA DE TIRO' },
  { id: 9, vehicle: 'LIVIANO', name: 'BULLBAR' },
  { id: 10, vehicle: 'LIVIANO', name: 'BUMPER' },
  { id: 11, vehicle: 'LIVIANO', name: 'CADENA Y CANDADO' },
  { id: 12, vehicle: 'LIVIANO', name: 'CAJON' },
  { id: 13, vehicle: 'LIVIANO', name: 'CAMARA DE RETRO' },
  { id: 14, vehicle: 'LIVIANO', name: 'DEFENSA CENTRAL' },
  { id: 15, vehicle: 'LIVIANO', name: 'DURALINER' },
  { id: 16, vehicle: 'LIVIANO', name: 'ESPEJOS' },
  { id: 17, vehicle: 'LIVIANO', name: 'ESTRIBOS' },
  { id: 18, vehicle: 'LIVIANO', name: 'FALDONES' },
  { id: 19, vehicle: 'LIVIANO', name: 'FORRO LLANTA DE EMERGENCIA' },
  { id: 20, vehicle: 'LIVIANO', name: 'FURGON TERMICO' },
  { id: 21, vehicle: 'LIVIANO', name: 'GUARDALODOS' },
  { id: 22, vehicle: 'LIVIANO', name: 'HALOGENOS' },
  { id: 23, vehicle: 'LIVIANO', name: 'JUEGO DE TAPICERIA' },
  { id: 24, vehicle: 'LIVIANO', name: 'KIT DEPORTIVO' },
  { id: 25, vehicle: 'LIVIANO', name: 'LAMINAS DE SEGURIDAD' },
  { id: 26, vehicle: 'LIVIANO', name: 'LLANTA DE EMERGENCIA' },
  { id: 27, vehicle: 'LIVIANO', name: 'LLANTAS' },
  { id: 28, vehicle: 'LIVIANO', name: 'MOLDURAS' },
  { id: 29, vehicle: 'LIVIANO', name: 'NEBLINEROS' },
  { id: 30, vehicle: 'LIVIANO', name: 'PANTALLA DE DVD' },
  { id: 31, vehicle: 'LIVIANO', name: 'PARLANTES' },
  { id: 32, vehicle: 'LIVIANO', name: 'PARRILLA' },
  { id: 33, vehicle: 'LIVIANO', name: 'PROTECCION ANTIVUELCOS' },
  { id: 34, vehicle: 'LIVIANO', name: 'PROTECTOR BALDE' },
  { id: 35, vehicle: 'LIVIANO', name: 'RADIO' },
  { id: 36, vehicle: 'LIVIANO', name: 'RECUBRIMIENTO DE BALDE' },
  { id: 37, vehicle: 'LIVIANO', name: 'ROLL BAR' },
  { id: 38, vehicle: 'LIVIANO', name: 'SENSOR DE PARQUEO' },
  { id: 39, vehicle: 'LIVIANO', name: 'SISTEMA BLUETOOTH' },
  { id: 40, vehicle: 'LIVIANO', name: 'SLAIDER' },
  { id: 41, vehicle: 'LIVIANO', name: 'SPOILER' },
  { id: 42, vehicle: 'LIVIANO', name: 'SUNROOF' },
  { id: 43, vehicle: 'LIVIANO', name: 'SUSPENSION' },
  { id: 44, vehicle: 'LIVIANO', name: 'TAPICERIA EN CUERO' },
  { id: 45, vehicle: 'LIVIANO', name: 'TECHO CORREDIZO' },
  { id: 46, vehicle: 'LIVIANO', name: 'TIMON DE LUJO' },
  { id: 47, vehicle: 'LIVIANO', name: 'TWEETER' },
  { id: 48, vehicle: 'LIVIANO', name: 'VIDRIOS' },
  { id: 49, vehicle: 'LIVIANO', name: 'VIDRIOS ELÉCTRICOS' },
  { id: 50, vehicle: 'LIVIANO', name: 'WINCHA' },

  { id: 51, vehicle: 'PESADO', name: 'AIRE ACONDICIONADO' },
  { id: 52, vehicle: 'PESADO', name: 'ALARMA' },
  { id: 53, vehicle: 'PESADO', name: 'ALERONES' },
  { id: 54, vehicle: 'PESADO', name: 'AMPLIFICADORES Y ECUALIZADORES' },
  { id: 55, vehicle: 'PESADO', name: 'AROS' },
  { id: 56, vehicle: 'PESADO', name: 'AROS Y LLANTAS' },
  { id: 57, vehicle: 'PESADO', name: 'BAÑERA' },
  { id: 58, vehicle: 'PESADO', name: 'BARRA ESTABILIZADORA' },
  { id: 59, vehicle: 'PESADO', name: 'BOLA DE TIRO' },
  { id: 60, vehicle: 'PESADO', name: 'BULLBAR' },
  { id: 61, vehicle: 'PESADO', name: 'BUMPER' },
  { id: 62, vehicle: 'PESADO', name: 'CADENA Y CANDADO' },
  { id: 63, vehicle: 'PESADO', name: 'CAJON' },
  { id: 64, vehicle: 'PESADO', name: 'CAMA ALTA' },
  { id: 65, vehicle: 'PESADO', name: 'CAMA BAJA' },
  { id: 66, vehicle: 'PESADO', name: 'CAMARA DE RETRO' },
  { id: 67, vehicle: 'PESADO', name: 'CAMAROTE' },
  { id: 68, vehicle: 'PESADO', name: 'DEFENSA CENTRAL' },
  { id: 69, vehicle: 'PESADO', name: 'ESPEJOS' },
  { id: 70, vehicle: 'PESADO', name: 'ESTRIBOS' },
  { id: 71, vehicle: 'PESADO', name: 'FALDONES' },
  { id: 72, vehicle: 'PESADO', name: 'FURGON' },
  { id: 73, vehicle: 'PESADO', name: 'FURGON TERMICO' },
  { id: 74, vehicle: 'PESADO', name: 'GUARDALODOS' },
  { id: 75, vehicle: 'PESADO', name: 'HALOGENOS' },
  { id: 76, vehicle: 'PESADO', name: 'JUEGO DE TAPICERIA' },
  { id: 77, vehicle: 'PESADO', name: 'LAMINAS DE SEGURIDAD' },
  { id: 78, vehicle: 'PESADO', name: 'LLANTA DE EMERGENCIA' },
  { id: 79, vehicle: 'PESADO', name: 'LLANTAS' },
  { id: 80, vehicle: 'PESADO', name: 'MATAMOSCAS' },
  { id: 81, vehicle: 'PESADO', name: 'MOLDURAS' },
  { id: 82, vehicle: 'PESADO', name: 'NEBLINEROS' },
  { id: 83, vehicle: 'PESADO', name: 'NIÑERAS' },
  { id: 84, vehicle: 'PESADO', name: 'PANTALLA DE DVD' },
  { id: 85, vehicle: 'PESADO', name: 'PARLANTES' },
  { id: 86, vehicle: 'PESADO', name: 'PARRILLA' },
  { id: 87, vehicle: 'PESADO', name: 'PLATAFORMA' },
  { id: 88, vehicle: 'PESADO', name: 'RADIO' },
  { id: 89, vehicle: 'PESADO', name: 'ROLL BAR' },
  { id: 90, vehicle: 'PESADO', name: 'SENSOR DE PARQUEO' },
  { id: 91, vehicle: 'PESADO', name: 'SISTEMA BLUETOOTH' },
  { id: 92, vehicle: 'PESADO', name: 'SISTEMA TERMICO DE REFRIGERACION' },
  { id: 93, vehicle: 'PESADO', name: 'SLAIDER' },
  { id: 94, vehicle: 'PESADO', name: 'SUSPENSION' },
  { id: 95, vehicle: 'PESADO', name: 'TANQUE' },
  { id: 96, vehicle: 'PESADO', name: 'TAPICERIA EN CUERO' },
  { id: 97, vehicle: 'PESADO', name: 'TOLVA' },
  { id: 98, vehicle: 'PESADO', name: 'TWEETER' },
  { id: 99, vehicle: 'PESADO', name: 'VIDRIOS' },
  { id: 100, vehicle: 'PESADO', name: 'WINCHA' },
];

export const inspectionStatusList = {
  inEmission: 'INEMISSION',
  linkForm: 'LINKFORM',
  inspection: 'INSPECTION',
  rejected: 'REJECTED',
  toConfirm: 'CONFIRM',
};

export const debugTypes = [
  { id: 0, name: 'Consultar formulario de vinculación' },
  { id: 1, name: 'Consultar canceladas' },
  { id: 2, name: 'Descargar documentos - póliza' },
  { id: 3, name: 'Comparar tarifa Emision Vs Cotizacion' },
];

export const filterTypesSearchPortal = [
  { id: 1, name: 'Placa' },
  { id: 2, name: 'Cotización' },
  { id: 3, name: 'Póliza' },
  { id: 4, name: 'Asegurado ID' },
];
